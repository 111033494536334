import { Modal } from "antd";
import React, { useState } from "react";
import data from "../styles/designParams.json";
const Disclaimer = (props) => {
  const [accept, setAccept] = useState(false);
  const changeHandler = (event) => {
    setAccept(event.target.checked);
  };

  return (
    <Modal
      className="colorT"
      visible={props.visible}
      title={props.title}
      onCancel={() => {
        props.onCancel();
        setAccept(false);
      }}
      footer={[
        <button
          key="close"
          className="BtnH"
          onClick={() => {
            setAccept(false);
            props.onCancel();
          }}
        >
          Close
        </button>,
        <button
          key="ok"
          className="BtnH ms-2"
          onClick={async () => {
            if (accept) {
              await props.onOk();
              setAccept(false);
              props.onCancel();
            }
          }}
          disabled={!accept}
        >
          OK
        </button>,
      ]}
    >
      <div>{data.disclaimer}</div>
      <div className="form-controls d-flex justify-content-start align-items-center mt-5">
        {" "}
        <input
          type="checkbox"
          name="acceptation"
          className="m-0 me-2"
          onChange={changeHandler}
          value={accept}
          checked={accept}
        />
        <label htmlFor="acceptation" className="m-0">
          I agree
        </label>
      </div>{" "}
    </Modal>
  );
};

export default Disclaimer;
