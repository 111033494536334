import React, { Component } from "react";
import { connect } from "react-redux";
import {
  SET_LOADING,
  SET_OWNER_WALLET,
  SET_REFRESH,
  SET_WALLET,
} from "../reducer/Main.reducer";

import { withRouter } from "react-router";
import { debounce } from "@material-ui/core";
import { Modal } from "antd";
import { AppParams } from "../config";
import { Link } from "react-router-dom";
import qs from "qs";

const providerOptions = {
  options: {
    networkParams: {},
    config: {
      buildEnv: "production", // optional
    },
  },
};

class WalletConnectButton extends Component {
  state = {
    fetching: false,
    address: "",
    web3: null,
    provider: null,
    connected: false,
    chainId: 5,
    networkId: 5,
    assets: [],
    showModal: false,
    pendingRequest: false,
    result: null,
    modalVisible: false,
    modalAirdropRecievedVisible: false,
  };

  onConnect = async () => {
    window.localStorage.setItem("logout", "false");
    this.props.dispatch({ type: SET_LOADING, payload: true });
    try {
      const { web3, provider, address, chainId, networkId } =
        await AppParams.open2web3.connect({ providerOptions });

      this.subscribeProvider(provider);
      this.setState({
        web3,
        provider,
        connected: true,
        address,
        chainId,
        networkId,
      });
      this.getAccountAssets();

      if (chainId * 1 !== AppParams.AppNetworkId) {
        provider
          .request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: AppParams.ChainProps.chainId,
              },
            ],
          })

          .then((result) => {
            console.log("wallet_addEthereumChain", result);
          })
          .catch((error) => {
            if (error.code === 4001) {
              console.log("We can't encrypt anything without the key.", error);
            } else {
              console.error(error);
            }
          });
      }
    } catch (err) {
      console.log("err message:", err);
      if (err == undefined) {
        this.setState({
          modalVisible: true,
        });
      }
    }
    this.props.dispatch({ type: SET_LOADING, payload: false });
  };

  getAccountAssets = async () => {
    this.props.dispatch({
      type: SET_WALLET,
      payload: {
        address: this.state.address,
        provider: this.state.provider,
        chainId: this.state.chainId,
        networkId: this.state.networkId,
      },
    });
  };

  subscribeProvider = (provider) => {
    if (!provider || !provider.on || provider.binded) {
      return;
    }
    provider.binded = true;

    provider.on("close", () => this.resetApp());
    // provider.on("message", (...e) => console.log('message', ...e));
    provider.on("accountsChanged", async (accounts) => {
      console.log("accountsChanged");
      if (!this._ismounted) return;
      this.setState({ address: accounts[0] }, this.getAccountAssets);
    });
    provider.on("chainChanged", async (chainId) => {
      if (!this._ismounted) return;
      const { web3 } = this.state;
      //const web3  = this.state;
      const networkId = await this.open2web3?.web3.eth.net.getId();
      this.setState({ chainId, networkId }, this.getAccountAssets);
    });

    provider.on("networkChanged", async (networkId) => {
      if (!this._ismounted) return;
      const { web3 } = this.state;
      const chainId = await this.open2web3?.web3.eth.chainId();
      this.setState({ chainId, networkId }, this.getAccountAssets);
    });
  };

  resetApp = async () => {
    await AppParams.open2web3.disconnect();
    this.setState({
      fetching: false,
      address: "",
      web3: null,
      provider: null,
      connected: false,
      chainId: 5,
      networkId: 5,
      assets: [],
      showModal: false,
      pendingRequest: false,
      result: null,
    });
    this.props.dispatch({ type: SET_WALLET, payload: { address: null } });
    window.localStorage.setItem("logout", "true");
    this.props.history.push("/");
  };

  onEvent = (e) => {
    if (e) {
      console.log("onEvent:", e);
      this.props.dispatch({ type: SET_REFRESH });
    } else {
      console.log("no onEvent:", e);
    }
  };

  componentDidMount() {
    this._ismounted = true;
    const logout = window.localStorage.getItem("logout");
    if (logout != undefined && logout != null && logout !== "true") {
      this.onConnect();
    }
    // open2web3
    //   .callContractToken("getOwner")
    //   .then((ownerAddress) =>
    //     this.props.dispatch({
    //       type: SET_OWNER_WALLET,
    //       payload: { address: ownerAddress },
    //     })
    //   )
    //   .catch(console.error);
    // this.onEventDebounce = debounce(this.onEvent, 1000);

    // console.log("Subscribe to crowdsale events");

    // this.listener = open2web3.contractEventListner(
    //   open2web3.getCrowdSaleContractAbi(),
    //   CrowsdaleContractAddress,
    //   function (error, result) {
    //     //this.props.dispatch({ type: SET_REFRESH });
    //     //console.log("event emitted from crowdsale contract", result)
    //   }
    // );
  }

  componentWillUnmount() {
    if (this.state.provider) this.state.provider.binded = false;
    this._ismounted = false;
  }
  render() {
    const { walletConnected } = this.props.store;
    return (
      <>
        {walletConnected &&
        this.props.store.networkId != AppParams.AppNetworkId ? (
          <Link onClick={this.onConnect} className="positionT">
            <div className="btn-switch-network">Incompatible network</div>
          </Link>
        ) : (
          <Link
            to="#"
            className="liensign"
            onClick={walletConnected ? this.resetApp : this.onConnect}
          >
            <span className="sign">
              {walletConnected ? "Sign out" : "Wallet Connect"}
            </span>
          </Link>
        )}

        <Modal
          visible={this.state.modalVisible}
          title="Erreur de connexion"
          footer={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn1"
                onClick={(e) => this.setState({ modalVisible: false })}
              >
                OK
              </button>
            </div>
          }
          onOk={(e) => this.setState({ modalVisible: false })}
          onCancel={(e) => this.setState({ modalVisible: false })}
        >
          <p>
            Vérifiez que le wallet est bien installé et configuré sur le réseau
            Binance Smart Chain
          </p>
        </Modal>
      </>
    );
  }
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(withRouter(WalletConnectButton));
