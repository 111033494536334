//list des addresses sur BSC TESTNET

const table_internationalisation = {
  /*pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect : 'Lines',
      nextAriaLabel : 'Page suivante',
      nextTooltip : 'Page suivante',
      lastAriaLabel : 'Dernière page',
      lastTooltip : 'Dernière page',
      firstAriaLabel : 'Première page',
      firstTooltip : 'Première page',
      previousAriaLabel : 'Page précédente',
      previousTooltip :'Page précédente'
  },
  toolbar: {
      nRowsSelected: '{0} ligne(s) sélectionné'
  },
  body: {
      emptyDataSourceMessage: 'Aucun enregistrement à afficher',
  }*/
};

export { table_internationalisation };
