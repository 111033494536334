import React, { Component } from 'react'
import { connect } from 'react-redux';


class Club extends Component {
    render() {
        return (
            <div className="bgpaper">
            <div className="container sectionpaper sectionpaper2">
                <div className="textCenter textM"><span className="title-dash w-700">IN. ANT. CLUB</span></div>

                <div className="ANTRectangleTSW"> 
                <div style={{display:'flex',flexDirection:'row',alignItems:'stretch',justifyContent:'space-between'}} className="borderBcard">
                    
                <div><img src="/images/LTT.jpeg" className="wIoa"/></div>  
               <div><h1 className="colB">GALEON TOKEN</h1></div>


                </div>
               
                <div className="pt-5">
                <div className="cardClub">
                <div>Name</div><div>GALEON</div>
                </div>
                <div className="cardClub">
                <div>Company</div> <div>GALEON</div>
                </div>
                
                <div className="cardClub">
                <div>Blockchain</div> <div>BSC / ETH</div>
                </div>
                <div className="cardClub">
                <div>Nationality</div> <div>FRENCH</div>
                </div>
                 <div className="borderBcard"></div>
                 <div className="cardClub pt-5">
                <div>Regulation</div> <div>NO</div>
                </div>
                <div className="cardClub">
                <div>Antvestor price</div> <div>0.03$</div>
                </div>
                <div className="cardClub">
                <div>Bonus</div> <div>NO</div>
                </div>
                <div className="cardClub">
                <div>Listing date</div> <div>Q1 2022</div>
                </div>
                <div className="cardClub">
                <div>Minimum investment</div> <div>200$</div>
                </div>

                <div><a href="https://docsend.com/view/ihqhz6nj2crkhpab" target="_blank" style={{color:"#0fff17"}} >Lite paper</a></div>
                </div>
                

                
                
               
                <div className="btnlist3 pt-5"> <a href="https://blockchain.galeon.care/auth/register" target="_blank">Join now</a></div>       
 
             
               
                </div>
               
                

                
  




            </div>
        
            </div>
        )
    }
}


const mapStateToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Club);

