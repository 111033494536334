import { AppParams } from "../config";

const initialState = {
  walletAddress: "",
  walletAddressShortForm: "",
  walletConnected: false,
  owner: "",
  isOwner: false,
  kycValid: false,
  myBalance: 0,
  availableTokens: 0,
  weiraised: 0,
  walletProvider: null,
  loading: false,
  infos: {},
  price: 0,
  deploymentEvent: {},
  networkId: null,
  key: 0,
  montantObjectif: 0,
  buyBackPrice: 0,
  buyBackPlatformFeesRate: 0,
  buyBackHoldersFeesRate: 0,
  buyBackWalletLiquidity: 0,
  buyBackMinimalValue: 0,
  nextWithdrawFeesTime: 0,
  currentFeesRate: null,
  withdrawFeesToRecieve: 0,
  stakingPoolParticipationPercetage: 0,
  purchaseIsFinished: false,
};

export const SET_WALLET = "SET_WALLET";
export const SET_ISREFFERAL_ACTOR = "SET_ISREFFERAL_ACTOR";
export const SET_TOKEN_INFOS = "SET_TOKEN_INFOS";
export const SET_TOTAL_SUPPLY = "SET_TOTAL_SUPPLY";
export const SET_SYMBOL = "SET_SYMBOL";
export const SET_DECIMALS = "SET_DECIMALS";
export const SET_MY_BALANCE = "SET_MY_BALANCE";
export const SET_TOKEN_PRICE = "SET_TOKEN_PRICE";
export const SET_BUYBACK_PRICE = "SET_BUYBACK_PRICE";
export const SET_BUYBACK_PLATFORM_FEES = "SET_BUYBACK_PLATFORM_FEES";
export const SET_BUYBACK_HOLDERS_FEES = "SET_BUYBACK_HOLDERS_FEES";
export const SET_BUYBACK_LIQUIDITY_BALANCE = "SET_BUYBACK_LIQUIDITY_BALANCE";
export const SET_BUYBACK_MINIMAL_VALUE = "SET_BUYBACK_MINIMAL_VALUE";
export const SET_DEPLOYMENT_INFO = "SET_DEPLOYMENT_INFO";
export const SET_AVAILABLE_TOKENS = "SET_AVAILABLE_TOKENS";
export const SET_OWNER_WALLET = "SET_OWNER_WALLET";
export const SET_WEIRAISED = "SET_WEIRAISED";
export const SET_LOADING = "SET_LOADING";
export const SET_REFRESH = "SET_REFRESH";
export const SET_KYC = "SET_KYC";
export const SET_MONTANT_OBJECTIF = "SET_MONTANT_OBJECTIF";
export const SET_DASHBOARD_LOADED = "SET_DASHBOARD_LOADED";
export const SET_NEXT_WITHDRAW_FEES_TIME = "SET_NEXT_WITHDRAW_FEES_TIME";
export const SET_WITHDRAW_FEES_RATE = "SET_WITHDRAW_FEES_RATE";
export const SET_WITHDRAW_FEES_TO_RECIEVE = "SET_WITHDRAW_FEES_TO_RECIEVE";
export const SET_STAKING_POOL_PERCENTAGE = "SET_STAKING_POOL_PERCENTAGE";
export const REFRESH_WITHDRAW_FEES = "REFRESH_WITHDRAW_FEES";
export const REFRESH_BUYBACK_HOLDERS_FEES = "REFRESH_BUYBACK_HOLDERS_FEES";
export const UPDATE_PURCHASE_IS_FINISHED = "UPDATE_PURCHASE_IS_FINISHED";

function MainReducer(state = initialState, action) {
  const { type, payload } = action;
  // console.log({ type, payload });
  switch (type) {
    case SET_WALLET:
      let walletAddressShortForm = "";
      if (payload.address) {
        let value = payload.address.match(
          /^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i
        );
        walletAddressShortForm = value[1] + "..." + value[3];
      }

      return {
        ...state,
        walletAddress: payload?.address,
        walletAddressShortForm,
        walletProvider: payload?.provider,
        walletConnected: !!payload?.address,
        networkId: payload?.networkId,
        chainId: payload?.chainId,
        isOwner: state.owner && state.owner === payload?.address,
      };

    case SET_ISREFFERAL_ACTOR:
      return { ...state, isRefferalActor: payload.isRefferalActor };
    case SET_OWNER_WALLET:
      return {
        ...state,
        owner: payload?.address,
        isOwner: payload?.address && payload?.address === state.walletAddress,
      };

    case SET_LOADING:
      return { ...state, loading: !!payload };

    case SET_MY_BALANCE:
      return {
        ...state,
        myBalance: payload > 0 ? parseFloat(payload).toLocaleString() : 0,
      };
    case SET_BUYBACK_LIQUIDITY_BALANCE:
      return {
        ...state,
        buyBackWalletLiquidity:
          payload > 0 ? parseFloat(payload).toLocaleString() : 0,
      };

    case SET_WEIRAISED:
      return {
        ...state,
        weiraised: payload > 0 ? parseFloat(payload).toLocaleString() : 0,
      };

    case SET_TOTAL_SUPPLY:
      return {
        ...state,
        totalSupply: payload > 0 ? parseFloat(payload).toLocaleString() : 0,
      };

    case SET_SYMBOL:
      return { ...state, symbol: payload != null ? payload : "" };

    case SET_DECIMALS:
      return { ...state, decimals: payload > 0 ? payload : 18 };

    case SET_AVAILABLE_TOKENS:
      return {
        ...state,
        availableTokens: payload > 0 ? parseFloat(payload).toLocaleString() : 0,
      };

    case SET_TOKEN_INFOS:
      return { ...state, infos: { ...payload } };

    case SET_KYC:
      return { ...state, kycValid: !!payload };

    case SET_TOKEN_PRICE:
      return {
        ...state,
        price:
          payload > 0
            ? (1 / parseFloat(payload / 10 ** 6)).toLocaleString()
            : 0,
      };

    case SET_BUYBACK_MINIMAL_VALUE:
      return { ...state, buyBackMinimalValue: payload > 0 ? payload : 0 };

    case SET_DEPLOYMENT_INFO:
      return { ...state, deploymentEvent: { ...payload } };

    case SET_REFRESH:
      return { ...state, key: state.key + 1 };

    case SET_MONTANT_OBJECTIF:
      return {
        ...state,
        montantObjectif: parseFloat(payload).toLocaleString(),
      };

    case SET_DASHBOARD_LOADED:
      return { ...state, dashboardLoaded: payload };

    case REFRESH_WITHDRAW_FEES:
      AppParams.open2web3
        .getContractStacking(AppParams.open2web3.provider)
        .methods.nextWithdrawFeesTime(AppParams.pid, state.walletAddress)
        .call()
        .then((value) => {
          AppParams.open2web3
            .getContractStacking(AppParams.open2web3.provider)
            .methods.withdrawFeesRate(AppParams.pid, state.walletAddress)
            .call()
            .then((valueFeesRate) => {
              action.asyncDispatch({
                type: SET_NEXT_WITHDRAW_FEES_TIME,
                payload: value,
              });
              action.asyncDispatch({
                type: SET_WITHDRAW_FEES_RATE,
                payload: valueFeesRate,
              });
            });
        });
      break;
    case UPDATE_PURCHASE_IS_FINISHED:
      return { ...state, purchaseIsFinished: action.payload };
    default:
      return { ...state };
  }
}

export default MainReducer;
