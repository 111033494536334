import { Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";
// import { Button } from "react-bootstrap";
import { Connect, useDispatch, useSelector } from "react-redux";
import { AppParams } from "../config";

const providerOptions = {
  options: {
    networkParams: {},
    config: {
      buildEnv: "production", // optional
    },
  },
};

function HowItWork() {
  const account = useSelector((state) => state.walletAddress);
  const dispatch = useDispatch();

  return (
    <>
      <div className="positionW">
        <ol className="list-group vertical-steps workS">
          <li className="list-group-item  my-2 Ant-step">
            <span className="p-2 d-flex justify-content-between align-items-center">
              <b> Download Metamask </b>
              <p className="m-0 p-0 d-flex justify-content-between  align-items-center">
                <Button>
                  {" "}
                  <a
                    href="https://metamask.io/download/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-inline-block mx-1"
                  >
                    Metamask
                  </a>
                </Button>
              </p>
            </span>
          </li>
          <li className="list-group-item my-2 ">
            <span className="d-flex justify-content-between align-items-center p-2">
              {" "}
              <b>{"Connect your wallet"}</b>
            </span>
          </li>
          {/* <li className="list-group-item my-2">
            <span className="d-flex justify-content-between align-items-center p-2">
              {" "}
              <b>
                {`${t("Configure your wallet on")} ${
                  AppParams.AppChainName
                } network`}
              </b>
              {account &&
                web3Api.provider &&
                web3Api.provider.provider &&
                AppParams.AppChainId != web3Api.provider.provider.chainId && (
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={() => {
                      handleNetworkSwitch(AppParams.AppChainId);
                    }}
                  >
                    {t("Change network")}
                  </Button>
                )}
            </span>
          </li> */}
          <li className="list-group-item my-2">
            <span className="p-2">
              {" "}
              <b>{"Buy Ether"}</b>
              <p>
                {
                  "If you're looking to purchase Ether and transfer it to your wallet, there are numerous options available to you. "
                }
              </p>
            </span>
          </li>
          <li className="list-group-item my-2">
            <span className="p-2">
              {" "}
              <b>{"Convert Ether to USDT"}</b>
              <p>
                {
                  "In order to participate to the ICO, you'll need to convert your Ether to USDT. It's recommended to keep at least $5 worth of Ether in your wallet to cover any transaction fees that may be incurred"
                }
              </p>
            </span>
          </li>
          <li className="list-group-item my-2">
            <span className="p-2">
              {" "}
              <b>{"Participate to the ICO"}</b>
              <p>{"Navigate to token Sale and complete your purchase"}</p>
            </span>
          </li>
        </ol>
      </div>
    </>
  );
}
export default HowItWork;
