import { connect } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "./layout/Layout";
import { Dashboard, Properties, TokenSale, Admin, Club } from "./pages";

function App({ store }) {
  const connected = !!store.walletAddress;
  const isNotOwner =
    !store.owner || !store.walletAddress || store.owner !== store.walletAddress;
  return (
    <BrowserRouter>
    {/*<div style={{display:'flex'}}>
      <div style={{width: '20%',background: '#0d0d10e3',display:'flex',flexDirection:'column',alignItems:'center',gap:'20px',paddingTop:'100px'}} className="btnLeft">
        
        <button style={{background:'blue'}}>ICO Saas</button>
        <button>Launchpad</button>
        <button>Marketplace NFT</button>
        <button >Staking</button>
        <button>DAO</button>
       
  </div>*/}
      <Layout>
        <Switch>
          <Route exact path="/" component={Dashboard} />

          <Route exact path="/token-sale" component={TokenSale} />
          {connected && isNotOwner ? (
            <Route exact path="/club" component={Club} />
          ) : null}

          <Route exact path="/properties" component={Properties} />
          {isNotOwner ? null : <Route exact path="/admin" component={Admin} />}
          {isNotOwner ? <Redirect to="/" /> : <Redirect to="/admin" />}
        </Switch>
      </Layout>

    
    </BrowserRouter>
  );
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(App);
