import React from "react";
//import wl from './Switch_design.json';
import styles from "../styles/designParams.json";
export default function SwitchDesign() {
  React.useEffect(() => {
    for (let element in styles["style"]) {
      document.documentElement.style.setProperty(
        element,
        styles["style"][element]
      );
    }
  }, []);

  return <></>;
}
