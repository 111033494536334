import React, { Component } from 'react'
import { connect } from 'react-redux';

class Properties extends Component {
  render() {
    return (
      <div className="Coming-soon">
        <img className="logo" src="/images/logo.png" alt="ANT ICO" />
        <span>Coming soon</span>
      </div>
    )
  }
}

const mapStateToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Properties);

