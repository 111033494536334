import moment from "moment";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import {
  SET_AVAILABLE_TOKENS,
  SET_DECIMALS,
  SET_DEPLOYMENT_INFO,
  SET_MY_BALANCE,
  SET_SYMBOL,
  SET_TOKEN_PRICE,
  SET_TOTAL_SUPPLY,
  SET_WEIRAISED,
} from "../reducer/Main.reducer";
import { AppParams } from "../config";

function AntBoxInfo({ type, store, dispatch, valueOnly }) {
  const [time, setTime] = useState("");
  let title = "";
  let subtitle = "";
  let value = "";
  let descripiron = "";
  let icon = "far fa-money-bill-alt";
  let color = "bg-lightBlue-500";
  let shortName = "";

  useLayoutEffect(() => {
    if (type !== "TotalSupplay") return;
    AppParams.open2web3
      .totalSupply()
      .then((payload) => dispatch({ type: SET_TOTAL_SUPPLY, payload }));
    AppParams.open2web3
      .symbol()
      .then((payload) => dispatch({ type: SET_SYMBOL, payload }));
    AppParams.open2web3
      .decimals()
      .then((payload) => dispatch({ type: SET_DECIMALS, payload }));
  }, [type]);

  useLayoutEffect(() => {
    if (type !== "MyBalance" || !store.walletAddress) return;
    console.log("call", type);
    //open2web3Provider.getBalanceByAccount(store.walletAddress)
    AppParams.open2web3
      .balanceOf(store.walletAddress)
      .then((payload) => dispatch({ type: SET_MY_BALANCE, payload }));
  }, [type, store.walletAddress, store.key]);

  useLayoutEffect(() => {
    if (type !== "Price") return;
    console.log("call", type);
    AppParams.open2web3
      .contractDeployedEvent(
        AppParams.CrowsdaleContractAddress,
        AppParams.CrowdSaleInitBlock,
        AppParams.CrowdSaleInitBlock
      )
      .then((payload) => dispatch({ type: SET_DEPLOYMENT_INFO, payload }));
    AppParams.open2web3.getRate().then((payload) => {
      console.log("action payload from getting price", payload);
      dispatch({ type: SET_TOKEN_PRICE, payload });
    });

    // open2web3
    //   .balanceOfPayementToken(BuyBackLiquidityAddress)
    //   .then((payload) =>
    //     dispatch({ type: SET_BUYBACK_LIQUIDITY_BALANCE, payload })
    //   );

    /*open2web3.getContractBuyBack(open2web3.provider).methods.buyBackMinimalValue().call().then(value=>{
      dispatch({ type: SET_BUYBACK_MINIMAL_VALUE, payload:open2web3.web3.utils.fromWei(value, "ether") })
    })
    open2web3.getContractStacking(open2web3.provider).methods.nextWithdrawFeesBlock(pid,store.walletAddress).call().then(value=>{
      dispatch({ type: SET_NEXT_WITHDRAW_FEES_TIME, payload:value })
    })*/
    /*open2web3.getBuyBackMinimalValue()
      .then(payload => dispatch({ type: SET_BUYBACK_MINIMAL_VALUE, payload }))*/
  }, [type]);

  useLayoutEffect(() => {
    if (type !== "TokensAvailable") return;
    console.log("call", type);
    //open2web3Provider.getBalanceByAccount(store.price.id)
    AppParams.open2web3
      .balanceOf(AppParams.CrowsdaleContractAddress)
      .then((payload) => dispatch({ type: SET_AVAILABLE_TOKENS, payload }));
  }, [type, store.deploymentEvent.id, store.key]);

  useLayoutEffect(() => {
    if (type !== "Time") return;
    console.log("call", type);
    var eventTime = moment(
      new Date(
        (store.deploymentEvent.closingTime == null
          ? 1651197786
          : store.deploymentEvent.closingTime) * 1000
      )
    );
    console.log("closingTime", store.deploymentEvent.closingTime);
    var interval = 1000;
    const t = setInterval(function () {
      var currentTime = moment(new Date());
      var duration = moment.duration(eventTime.diff(currentTime));
      var days = Math.floor(duration.asDays());
      duration.subtract(moment.duration(days, "days"));
      var hours = duration.hours();
      duration.subtract(moment.duration(hours, "hours"));
      var minutes = duration.minutes();
      duration.subtract(moment.duration(minutes, "minutes"));
      var seconds = duration.seconds();
      const getVal = (val, unite) => {
        return val + " " + unite + (val > 1 ? "s" : "");
      };
      if (duration) {
        setTime(
          `${getVal(days, "jour")}, ${getVal(hours, "heure")} ${getVal(
            minutes,
            "minute"
          )} ${getVal(seconds, "seconde")}`
        );
      }
    }, interval);

    return () => clearInterval(t);
  }, [type, store.deploymentEvent.closingTime]);

  useLayoutEffect(() => {
    if (type !== "Weiraised" || !AppParams.open2web3.web3) return;
    try {
      AppParams.open2web3.weiraised().then((payload) =>
        dispatch({
          type: SET_WEIRAISED,
          payload: AppParams.open2web3.web3.utils.fromWei(
            payload + "",
            "ether"
          ),
        })
      );
    } catch (err) {
      console.error(err);
    }
  }, [type, AppParams.open2web3.web3, store.key]);

  useEffect(() => {
    if (store.purchaseIsFinished) {
      AppParams.open2web3
        .balanceOf(store.walletAddress)
        .then((payload) => dispatch({ type: SET_MY_BALANCE, payload }));
      AppParams.open2web3
        .balanceOf(AppParams.CrowsdaleContractAddress)
        .then((payload) => dispatch({ type: SET_AVAILABLE_TOKENS, payload }));
      try {
        AppParams.open2web3.weiraised().then((payload) =>
          dispatch({
            type: SET_WEIRAISED,
            payload: AppParams.open2web3.web3.utils.fromWei(
              payload + "",
              "ether"
            ),
          })
        );
      } catch (err) {
        console.error(err);
      }
    }
  }, [store.purchaseIsFinished]);
  switch (type) {
    case "TotalSupplay":
      title = "Total issued tokens";
      subtitle = title;
      value = store.totalSupply;
      descripiron = store.symbol;
      color = "bg-red-500";
      icon = "fas fa-users";
      shortName = "Tokens émis";
      break;

    case "MyBalance":
      if (!store.walletAddress) return null;
      title = "My balance";
      subtitle = title;
      value = store.myBalance;
      descripiron = store.symbol;
      color = "bg-pink-500";
      break;

    case "Price":
      title = "Current Token Price";
      subtitle = title;
      value = store.price + " USD";
      //descripiron = store.symbol;
      //descripiron = "BUSD";
      icon = "fas fa-tag";
      // shortName = 'Prix';
      break;

    case "TokensAvailable":
      title = "Tokens available";
      subtitle = title;
      value = store.availableTokens == undefined ? "--" : store.availableTokens;
      descripiron = store.symbol;
      color = "bg-orange-500";
      shortName = "Tokens available";
      break;

    case "phaseObjective":
      title = "Objectif de la phase";
      subtitle = title;
      value = store.montantObjectif == undefined ? "--" : store.montantObjectif;
      //descripiron = store.symbol;
      descripiron = "USD";
      color = "bg-orange-500";
      shortName = "Objectif de la phase";
      break;

    case "Time":
      title = "Close in";
      subtitle = title;
      value = time;
      color = "bg-orange-500";
      icon = "far fa-clock";
      break;

    case "Wallet":
      if (!store.walletAddress) return null;
      title = "Mon wallet";
      subtitle = title;
      value = store.walletAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
      value = value[1] + "..." + value[3];
      color = "bg-pink-500";
      icon = "fas fa-wallet";
      break;

    case "WalletContract":
      if (!store.walletAddress) return null;
      title = "Contrat address";
      subtitle = title;
      /*value = TokenContractAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);*/
      value = AppParams.TokenContractAddress;
      color = "bg-pink-500";
      icon = "fas fa-wallet";
      break;

    case "Weiraised":
      title = "Collection fund";
      subtitle = title;
      value = store.weiraised;
      descripiron = "USDT";
      color = "bg-pink-500";
      icon = "fas fa-wallet";
      break;

    default:
      return null;
  }

  const handleClick = () => {
    if (type === "WalletContract") {
      window.open(
        AppParams.blockExplorerUrl + "/token/" + AppParams.TokenContractAddress,
        "_blank"
      );
    }
  };

  if (valueOnly)
    return `${shortName} ${shortName ? ":" : ""} ${value ? value : ""} ${
      descripiron ? descripiron : ""
    }`;

  return (
    <div className={`AntBoxInfo AntBoxInfo-${type}`} onClick={handleClick}>
      {/*<span><span className="glyphicon glyphicon-tag"></span></span>*/}
      <div>
        <span>{title}</span>
        <h3>
          {value} {descripiron}
        </h3>
      </div>
    </div>
  );
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

AntBoxInfo.LinkedCard = connect(mapState, mapDispatch)(AntBoxInfo);

AntBoxInfo.TotalSupplay = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"TotalSupplay"} />
);
AntBoxInfo.MyBalance = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"MyBalance"} />
);
AntBoxInfo.Price = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"Price"} />
);
AntBoxInfo.TokensAvailable = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"TokensAvailable"} />
);
AntBoxInfo.Time = (props) => <AntBoxInfo.LinkedCard {...props} type={"Time"} />;
AntBoxInfo.Wallet = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"Wallet"} />
);
AntBoxInfo.Weiraised = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"Weiraised"} />
);
AntBoxInfo.WalletContract = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"WalletContract"} />
);
AntBoxInfo.phaseObjective = (props) => (
  <AntBoxInfo.LinkedCard {...props} type={"phaseObjective"} />
);

export default AntBoxInfo;
