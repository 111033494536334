import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { tokenClient, getBalancesList } from "./graphql";
import { table_internationalisation } from "./open2web3";
import Spinner from "react-spinner-material";
import { AppParams } from "../config";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const tableColumnConfig = [
  {
    title: "Address",
    field: "address",
    render: (row) => {
      let m =
        row.address &&
        row.address.match &&
        row.address.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
      return (
        <div className="table-cell">
          <div
            className="tx-field-tables"
            data-toggle="tooltip"
            data-placement="top"
            titre={row.address}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${AppParams.blockExplorerUrl}/address/${row.address}`}
            >
              {m ? m[1] + "..." + m[3] : ""}
            </a>
          </div>
        </div>
      );
    },
  },
  {
    title: "Balance",
    field: "amount",
    render: (row) => {
      return <div className="table-cell">{row.amount}</div>;
    },
  },
];

const remoteData = (query) => {
  return tokenClient
    .query({
      query: getBalancesList,
      variables: {
        offset: query.page * query.pageSize,
        limit: query.pageSize,
        orderBy: query.orderBy == undefined ? "amount" : query.orderBy.field,
        orderDirection:
          query.orderDirection == undefined || query.orderDirection == ""
            ? "desc"
            : query.orderDirection,
      },
    })
    .then((res) => {
      setTimeout(() => {
        window.$("div[data-toggle]").tooltip({});
      }, 1000);

      return {
        data: res.data.accountBalances,
        page: query.page,
        totalCount:
          res.data.tokens[0].holderCount === undefined
            ? 0
            : parseInt(res.data.tokens[0].holderCount) - 1,
      };
    });
};

const BalancesList = (props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }, []);

  return (
    <>
      <div className="textCenter textM">
        <span className="title-dash w-700">Account balance</span>
      </div>

      <div className=" row ANTRectangleTS mb-5" style={{ paddingBottom: 0 }}>
        <div style={{ minHeight: 300 }} className="tabalance">
          {/* <table>
    <tr>
        <td>
            <div className="table-cell">
                content
            </div>
        </td>
        <td>
            <div className="table-cell">
                content
            </div>
        </td>
        <td>
            <div className="table-cell">
                content
            </div>
        </td>
    </tr>
</table>
 */}

          {visible ? (
            <MaterialTable
              icons={tableIcons}
              columns={tableColumnConfig}
              data={remoteData}
              options={{
                toolbar: false,
                showFirstLastPageButtons: false,
              }}
              components={{
                OverlayLoading: (props) => (
                  <div className="spinner">
                    {" "}
                    <Spinner visible={true} />
                  </div>
                ),
              }}
              localization={table_internationalisation}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default BalancesList;
