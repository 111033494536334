import React, { Component } from "react";
import { connect } from "react-redux";
import AchatToken from "../common/AchatToken";
import AntBoxInfo from "../common/AntBoxInfo";
import BalancesList from "../common/BalancesList";
import TransactionsList from "../common/TransactionsList";

class TokenSale extends Component {
  render() {
    /* return (
      <div className="Coming-soon">
        <img className="logo" src="/images/logo.png" alt="ANT ICO" />
        <span>Coming soon</span>
      </div>
    ); */

    const connected = !!this.props.store.walletAddress;
    return (
      <div className="bgtoken">
        <div className="container sectiontoken pt-50">
          <div className="row">
            <div
              className={
                connected ? "col-sm-12 col-xs-12" : "col-sm-8 col-xs-12"
              }
              style={{ display: "flex" }}
            >
              <div className="row ANTRectangleTS classAlign">
                <div className="col-md-4 col-sm-6">
                  <AntBoxInfo.TotalSupplay />
                </div>
                <div className="col-md-4 col-sm-6">
                  <AntBoxInfo.Price />
                </div>
                <div className="col-md-4 col-sm-6">
                  <AntBoxInfo.TokensAvailable />
                </div>
                <div className="col-md-4 col-sm-6">
                  <AntBoxInfo.Time />
                </div>
                {connected ? (
                  <div className="col-md-4 col-sm-6">
                    <AntBoxInfo.Wallet />
                  </div>
                ) : null}

                <div className="col-md-4 col-sm-6">
                  <AntBoxInfo.Weiraised />
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-xs-12" style={{ display: "flex" }}>
              {connected ? <div></div> : <AchatToken />}
            </div>
          </div>
          {connected ? (
            <>
              <div className="row ANTRectangleTS classAlign">
                <div className="col-md-4 col-sm-6">
                  <AntBoxInfo.MyBalance />
                </div>

                <div className="col-md-8 col-sm-6">
                  <AntBoxInfo.WalletContract />
                </div>
              </div>
              <div className="row pt-5">
                <div
                  className="col-sm-12 col-xs-12 tokenConn"
                  style={{ display: "flex" }}
                >
                  <AchatToken />
                </div>
              </div>
            </>
          ) : null}
          <BalancesList />
          <TransactionsList />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(TokenSale);
