import Open2web3Service from "../services/Open2web3Service";

const chainProps = {
  chainId: "0x5",
  chainName: "Goerli Test Network",
  nativeCurrency: {
    name: "Goerli Ether",
    symbol: "GoETH",
    decimals: 18,
  },
  rpcUrls: ["https://rpc.goerli.mudit.blog/"],
  blockExplorerUrls: ["https://goerli.etherscan.io/"],
};

const crowsdaleContractAddress = "0x904c849748523c80CDa454783686CB5cba35C3b6";
const USDTContractAddress = "0xcE3D8656Ef5050481eC5D0404d6c8D1130fe3789";
const tokenContractAddress = "0x407F111e3188faB981f8De6E1Bc9Ac485e042689";
const whiteListContractAddress = "0x2dBb5f6f681D92dCD37C270f6D79804c52Ca2D98";

const open2web3 = new Open2web3Service({
  addressCrowdfounding: crowsdaleContractAddress,
  addressUSDT: USDTContractAddress,
  adressToken: tokenContractAddress,
  adressWhiteList: whiteListContractAddress,
});

window._open2web3 = open2web3;

const open2web3Provider = new Open2web3Service.Open2web3Provider({
  icoClient: {
    uri: "https://api.studio.thegraph.com/query/8345/crowdsaleant/v0.0.1",
  },
  tokenClient: {
    uri: "https://api.thegraph.com/subgraphs/name/icoantsub/bep20-ant",
  },
  whitelistedClient: {
    uri: "https://api.thegraph.com/subgraphs/name/chefhaker/whitelistdevlbk",
  },
});

export const getOrCreateSignature = async function (address, signerAddress) {
  let signature = localStorage.getItem("signedAdressTransaction");
  if (
    signature != null &&
    signature != "" &&
    signerAddress == localStorage.getItem("signerAdressTransaction")
  ) {
    return signature;
  }
  //console.log("open2web3",open2web3.accounts[0])
  //var address = open2web3.accounts[0].substring(2);
  if (open2web3.web3.currentProvider.isMetaMask !== true) {
    signature = await window.BinanceChain.request({
      method: "eth_sign",
      params: [signerAddress, address],
    });
  } else {
    signature = await open2web3.web3.eth.personal.sign(address, signerAddress);
  }
  localStorage.setItem("signedAdressTransaction", signature);
  localStorage.setItem("signerAdressTransaction", signerAddress);
  return signature;
};

export const AppParams_prod = {
  AppNetworkId: 5,
  CrowsdaleContractAddress: crowsdaleContractAddress,
  USDTContractAddress: USDTContractAddress,
  TokenContractAddress: tokenContractAddress,
  WhiteListContractAddress: whiteListContractAddress,
  tokenDecimals: 18,
  tokenSymbol: "O2I",
  CrowdSaleInitBlock: "8861081",
  ChainProps: chainProps,
  apiEndpoint: "https://app.fundraising.o2bprcl.xyz",
  blockExplorerUrl: "https://goerli.etherscan.io/",
  BlockPassId: "ico_ape_dev_8d5fd",
  open2web3: open2web3,
  open2web3Provider: open2web3Provider,
  pid: 0,
  getOrCreateSignature: getOrCreateSignature,
};
