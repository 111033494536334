import Web3 from "web3";
import { CrowdsaleAbi, WhitelistAbi, Erc20TokenAbi } from "./contractsAbis";
import Open2web3Provider from "./open2web3Provider";

import HDWalletProvider from "@truffle/hdwallet-provider";
import Onboard from "@web3-onboard/core";
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets";
import torusModule from "@web3-onboard/torus";

const torus = torusModule();
const MAINNET_RPC_URL =
  "https://goerli.infura.io/v3/083268db1d764794807c32c219a17cd6";

export const injected = injectedModule({
  filter: {
    [ProviderLabel.Binance]: false,
    // [ProviderLabel.Coinbase]: false,
    [ProviderLabel.Phantom]: false,
    [ProviderLabel.SafePal]: false,
    [ProviderLabel.Zerion]: false,
    [ProviderLabel.OKXWallet]: false,
    [ProviderLabel.Tally]: false,
    [ProviderLabel.Opera]: false,
    [ProviderLabel.Status]: false,
    [ProviderLabel.AlphaWallet]: false,
    [ProviderLabel.AToken]: false,
    [ProviderLabel.Bitpie]: false,
    [ProviderLabel.BlockWallet]: false,
    [ProviderLabel.Dcent]: false,
    [ProviderLabel.Frame]: false,
    [ProviderLabel.HuobiWallet]: false,
    [ProviderLabel.HyperPay]: false,
    [ProviderLabel.ImToken]: false,
    [ProviderLabel.Liquality]: false,
    [ProviderLabel.MeetOne]: false,
    [ProviderLabel.MyKey]: false,
    [ProviderLabel.OwnBit]: false,
    [ProviderLabel.TokenPocket]: false,
    [ProviderLabel.TP]: false,
    [ProviderLabel.XDEFI]: false,
    [ProviderLabel.OneInch]: false,
    [ProviderLabel.Tokenary]: false,
    [ProviderLabel.DeFiWallet]: false,
    [ProviderLabel.GameStop]: false,
    [ProviderLabel.Rabby]: false,
    [ProviderLabel.MathWallet]: false,
    [ProviderLabel.BitKeep]: false,
    [ProviderLabel.Sequence]: false,
    [ProviderLabel.Core]: false,
    [ProviderLabel.Bitski]: false,
    [ProviderLabel.Enkrypt]: false,
    [ProviderLabel.Zeal]: false,
    [ProviderLabel.Exodus]: false,
    [ProviderLabel.Frontier]: false,
    [ProviderLabel.Rainbow]: false,
    [ProviderLabel.DeFiWallet]: false,
    [ProviderLabel.ApexWallet]: false,
    [ProviderLabel.BifrostWallet]: false,
  },
});

// initialize the module with options

/*End Wallet connect*/
export const onboard = Onboard({
  wallets: [injected, torus],
  // connect: { autoConnectLastWallet: true, showSidebar: false },
  connect: { showSidebar: false },
  accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },

  chains: [
    {
      id: 5,
      token: "ETH",
      label: "Goerli",
      rpcUrl: MAINNET_RPC_URL,
    },
  ],
});

class Open2web3Service {
  static Open2web3Provider = Open2web3Provider;

  constructor(options = {}) {
    if ("addressCrowdfounding" in options) {
      this._address = options.addressCrowdfounding;
    }
    if ("addressUSDT" in options) {
      this._addressUSDT = options.addressUSDT;
    }
    if ("adressToken" in options) {
      this._adressToken = options.adressToken;
    }
    if ("adressWhiteList" in options) {
      this._adressWhiteList = options.adressWhiteList;
    }
  }

  setAddress = (address) => {
    this._address = address;
  };

  getContractCrowdsale = (provider) => {
    return this.getContract(provider, CrowdsaleAbi, this._address);
  };
  getContractWhiteList = (provider) => {
    return this.getContract(provider, WhitelistAbi, this._adressWhiteList);
  };
  getContractToken = (provider) => {
    return this.getContract(provider, Erc20TokenAbi, this._adressToken);
  };
  getContractUSDT = (provider) => {
    return this.getContract(provider, Erc20TokenAbi, this._addressUSDT);
  };

  getContract = (provider, abi, address) => {
    const web3 = new Web3(provider);
    return new web3.eth.Contract(abi, address);
  };

  getAmountToReceiveFromContract = async (valueToSend) => {
    var value = await this.contract.methods
      .getTokenAmount(this.web3.utils.toWei(valueToSend, "ether"))
      .call();
    return {
      valueToReceive: this.web3.utils.fromWei(value, "ether"),
    };
  };

  getTokenAmount = async (valueToSend) => {
    var value = await this.contract.methods
      .getTokenAmount(this.web3.utils.toWei(valueToSend, "ether"))
      .call();
    return this.web3.utils.fromWei(value, "ether");
  };

  buyTokens = (beneficiary, value) => {
    return this.contract.methods
      .buyTokens(beneficiary, this.web3.utils.toWei(value, "ether"))
      .send({
        from: beneficiary,
      });
  };

  transferBlocked = () => {
    return this.getContractToken(this.virtualProvider)
      .methods._transferBlocked()
      .call();
  };

  setTransferBlocked = (blockedStatus, account) => {
    return this.contractToken.methods.setTransferBlocked(blockedStatus).send({
      from: account,
    });
  };

  approveUSDT = (account, spender, value) => {
    return this.contractUSDT.methods
      .approve(spender, this.web3.utils.toWei(value, "ether"))
      .send({
        from: account,
      });
  };
  allowedUSDT = (account, spender) => {
    return this.contractUSDT.methods.allowance(account, spender).call();
  };

  approveToken = (account, spender, value) => {
    return this.contractToken.methods
      .approve(spender, this.web3.utils.toWei(value, "ether"))
      .send({
        from: account,
      });
  };

  options = {
    timeout: 100000, // ms

    // Useful for credentialed urls, e.g: ws://username:password@localhost:8546
    /*headers: {
      authorization: 'Basic username:password'
    },*/

    clientConfig: {
      // Useful if requests are large
      maxReceivedFrameSize: 100000000, // bytes - default: 1MiB
      maxReceivedMessageSize: 100000000, // bytes - default: 8MiB

      // Useful to keep a connection alive
      keepalive: true,
      keepaliveInterval: 60000, // ms
    },

    // Enable auto reconnection
    reconnect: {
      auto: true,
      delay: 50000, // ms
      maxAttempts: 5,
      onTimeout: false,
    },
  };

  wsProvider = new Web3.providers.WebsocketProvider(
    "wss://goerli.infura.io/ws/v3/7a8b22693c3e495487b225e6842ec87f",
    this.options
  );
  virtualProvider = new HDWalletProvider({
    mnemonic: {
      phrase:
        "stone picture more humor drift swear slender tape quantum citizen era task paddle swear silver",
    },
    //providerOrUrl: "https://apis.ankr.com/d057aca859fa4e26b1e8b57cd3c98bad/30fd0fd60dc19a950310dada8e0259a9/binance/full/test"
    providerOrUrl: this.wsProvider,
  });

  getVirtualWeb3() {
    return new Web3(this.virtualProvider);
  }

  balanceOf = (account) => {
    //return this.getContractToken(this.virtualProvider).methods.balanceOf(account).call();
    const virtualWeb3 = new Web3(this.virtualProvider);
    return new Promise(async (resolve, reject) => {
      this.getContractToken(this.virtualProvider)
        .methods.balanceOf(account)
        .call()
        .then((balance) => {
          resolve(
            parseFloat(virtualWeb3.utils.fromWei(balance, "ether")).toFixed(2)
          );
        });
    });
  };

  balanceOfPaymentToken = (account) => {
    const virtualWeb3 = new Web3(this.virtualProvider);
    return new Promise(async (resolve, reject) => {
      this.getContractUSDT(this.virtualProvider)
        .methods.balanceOf(account)
        .call()
        .then((balance) => {
          resolve(
            parseFloat(virtualWeb3.utils.fromWei(balance, "ether")).toFixed(2)
          );
        });
    });
  };

  totalSupply = () => {
    const virtualWeb3 = new Web3(this.virtualProvider);
    return new Promise(async (resolve, reject) => {
      this.getContractToken(this.virtualProvider)
        .methods.totalSupply()
        .call()
        .then((totalSupply) => {
          console.log("totalSupply", totalSupply);
          resolve(virtualWeb3.utils.fromWei(totalSupply, "ether"));
        });
    });
  };

  weiraised = () => {
    return this.getContractCrowdsale(this.virtualProvider)
      .methods.weiRaised()
      .call();
  };

  getRate = () => {
    return this.getContractCrowdsale(this.virtualProvider)
      .methods.rate()
      .call();
  };

  contractDeployedEvent = (contractAddress, fromBlock, toBlock) => {
    const virtualWeb3 = new Web3(
      "wss://goerli.infura.io/ws/v3/7a8b22693c3e495487b225e6842ec87f"
    );

    const contractVirtual = new virtualWeb3.eth.Contract(
      CrowdsaleAbi,
      contractAddress
    );
    return new Promise(async (resolve, reject) => {
      contractVirtual.events.ContractDeployed(
        {
          filter: {},
          fromBlock: fromBlock,
          toBlock: toBlock,
        },
        function (error, event) {
          console.log("ContractDeployed error", error);
          console.log("ContractDeployed", event);
          resolve(event.returnValues);
        }
      );
    });
  };

  isWhitelisted = async (account) => {
    if (account != null) {
      const isWhitelisted = await this.getContractWhiteList(
        this.virtualProvider
      )
        .methods.isAutorized(account)
        .call();

      return isWhitelisted;
    }
  };

  symbol = () => {
    return this.getContractToken(this.virtualProvider).methods.symbol().call();
  };

  decimals = () => {
    return this.getContractToken(this.virtualProvider)
      .methods.decimals()
      .call();
  };

  callContractToken = (fnName, ...args) => {
    return this.getContractToken(this.virtualProvider)
      .methods[fnName](...args)
      .call();
  };

  contractEventListner = (contractAbi, contractAddress, callback) => {
    const virtualWeb3 = new Web3(
      "wss://goerli.infura.io/ws/v3/7a8b22693c3e495487b225e6842ec87f"
    );

    const contractVirtual = new virtualWeb3.eth.Contract(
      contractAbi,
      contractAddress
    );
    return contractVirtual.events.allEvents({ fromBlock: "latest" }, callback);
  };

  getCrowdSaleContractAbi = () => {
    return CrowdsaleAbi;
  };
  getWhitelistContractAbi = () => {
    return WhitelistAbi;
  };
  getTokenContractAbi = () => {
    return Erc20TokenAbi;
  };

  static initWeb3(provider) {
    const web3 = new Web3(provider);
    web3.eth.extend({
      methods: [
        {
          name: "chainId",
          call: "eth_chainId",
          outputFormatter: web3.utils.hexToNumber,
        },
      ],
    });
    return web3;
  }

  connect = async ({ providerOptions, cacheProvider = true }) => {
    this.onBoard = Onboard({
      wallets: [injected, torus],
      // connect: { autoConnectLastWallet: true, showSidebar: false },
      connect: { showSidebar: false },
      accountCenter: {
        desktop: { enabled: false },
        mobile: { enabled: false },
      },

      chains: [
        {
          id: 5,
          token: "ETH",
          label: "Goerli",
          rpcUrl: MAINNET_RPC_URL,
        },
      ],
    });

    // }
    let provider;
    // Loading...
    // Get all the accounts and the user will be able to choose one.
    let wallets;
    try {
      let wallet = window.localStorage.getItem("wallet");
      if (
        wallet !== undefined &&
        wallet !== "undefined" &&
        wallet !== null &&
        wallet !== "null"
      ) {
        wallets = await onboard.connectWallet({ autoSelect: wallet });
      } else {
        wallets = await onboard.connectWallet();
      }
      if (wallets[0]) {
        provider = wallets[0].provider;
        window.localStorage.setItem(
          "wallet",
          onboard.state.get().wallets[0].label
        );
        this.provider = wallets[0].provider;
      } else {
        return;
      }

      window.onBoardProvider = wallets[0].provider;

      localStorage.setItem("loggedOut", "false");

      // Loading...
    } catch (error) {
      console.log(error);
      localStorage.setItem("loggedOut", "true");

      return;
    }

    this.web3 = Open2web3Service.initWeb3(this.provider);
    this.accounts = await this.web3.eth.getAccounts();
    this.address = this.accounts[0];
    this.contract = new this.web3.eth.Contract(CrowdsaleAbi, this._address);

    this.contractToken = new this.web3.eth.Contract(
      Erc20TokenAbi,
      this._adressToken
    );
    this.contractUSDT = new this.web3.eth.Contract(
      Erc20TokenAbi,
      this._addressUSDT
    );
    this.contractWhiteList = new this.web3.eth.Contract(
      WhitelistAbi,
      this._adressWhiteList
    );
    this.networkId = await this.web3.eth.net.getId();
    this.networkType = await this.web3.eth.net.getNetworkType();
    //this.peerCount = await this.web3.eth.net.getPeerCount();
    this.chainId = await this.web3.eth.getChainId();
    const obj = {
      web3: this.web3,
      contract: this.contract,
      contractToken: this.contractToken,
      contractUSDT: this.contractUSDT,
      contractWhiteList: this.contractWhiteList,
      provider: this.provider,
      address: this.address,
      chainId: this.chainId,
      networkId: this.networkId,
    };
    return obj;
  };

  disconnect = async () => {
    localStorage.setItem("loggedOut", "true");
    const [primaryWallet] = onboard.state.get().wallets;
    await onboard.disconnectWallet({ label: primaryWallet.label });
    localStorage.setItem("loggedOut", "true");
    localStorage.setItem("wallet", null);
  };
}

export { Open2web3Provider, Open2web3Service };
export default Open2web3Service;
