import { AppParams_dev } from "./environnements/envDev";
import { AppParams_local } from "./environnements/envLocal";

import { AppParams_prod } from "./environnements/envProd";
import { AppParams_recette } from "./environnements/envRecette";

// export const currentMode = "recette";
export const currentMode = "dev";
// export const currentMode = "local";

function getAppParams() {
  if (currentMode === "dev") {
    return AppParams_dev;
  } else if (currentMode === "prod") {
    return AppParams_prod;
  } else if (currentMode === "recette") {
    return AppParams_recette;
  } else if (currentMode === "local") {
    return AppParams_local;
  }
  return AppParams_dev;
}

export const AppParams = getAppParams();
