import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { tokenClient, getTransactionsList } from "./graphql";
import { table_internationalisation } from "./open2web3";
import moment from "moment-timezone";
import Spinner from "react-spinner-material";
import { AppParams } from "../config";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const tableColumnConfig = [
  //{
  // title: 'Transaction',
  //field: 'transaction',
  //render: row => {
  // let m = row.transaction && row.transaction.match && row.transaction.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
  //return <div className="tx-field" data-toggle="tooltip" data-placement="top" title={row.transaction}>{m ? m[1] + '...' + m[3] : ''}</div>;
  //return <div className="tx-field-tables tx-field" data-toggle="tooltip" data-placement="top" titre={row.transaction} ><a target="_blank" rel="noopener noreferrer" href={`${blockExplorerUrl}/tx/${row.transaction}`}>{m ? m[1] + '...' + m[3] : ''}</a></div>
  //}
  //},
  {
    title: "Sender",
    field: "sender",
    render: (row) => {
      let m =
        row.sender &&
        row.sender.match &&
        row.sender.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
      return (
        <div
          className="tx-field"
          data-toggle="tooltip"
          data-placement="top"
          title={row.sender}
        >
          {m ? m[1] + "..." + m[3] : ""}
        </div>
      );
      return (
        <div
          className="tx-field-tables tx-field"
          data-toggle="tooltip"
          data-placement="top"
          titre={row.sender}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${AppParams.blockExplorerUrl}/address/${row.sender}`}
          >
            {m ? m[1] + "..." + m[3] : ""}
          </a>
        </div>
      );
    },
  },
  {
    title: "Destination",
    field: "destination",
    render: (row) => {
      let m =
        row.destination &&
        row.destination.match &&
        row.destination.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
      //return <div className="tx-field" data-toggle="tooltip" data-placement="top" title={row.destination} >{m ? m[1] + '...' + m[3] : ''}</div>;
      return (
        <div
          className="tx-field-tables tx-field"
          data-toggle="tooltip"
          data-placement="top"
          titre={row.destination}
        >
          <a
            data-toggle="tooltip"
            target="_blank"
            rel="noopener noreferrer"
            href={`${AppParams.blockExplorerUrl}/address/${row.destination}`}
          >
            {m ? m[1] + "..." + m[3] : ""}
          </a>
        </div>
      );
    },
  },
  {
    title: "Amount",
    field: "amount",
  },
  //{
  //title: 'Date',
  //field: 'timestamp',
  //render: row => {
  //moment(Number.parseInt(row["timestamp"])).format('DD/MM/YYYY HH:mm:ss')

  // var a = new Date(row["timestamp"] * 1000);
  //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  //var year = a.getFullYear();
  //var month = months[a.getMonth()];
  //var date = a.getDate();
  //var hour = a.getHours();
  //var min = a.getMinutes();
  //var sec = a.getSeconds();
  //var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min ;//+ ':' + sec ;
  // return time;
  // }
  // }
];

const remoteData = (query) => {
  return tokenClient
    .query({
      query: getTransactionsList,
      variables: {
        offset: query.page * query.pageSize,
        limit: query.pageSize,
        orderBy:
          query.orderBy === undefined ? "timestamp" : query.orderBy.field,
        orderDirection:
          query.orderDirection === undefined || query.orderDirection === ""
            ? "desc"
            : query.orderDirection,
      },
    })
    .then((res) => {
      setTimeout(() => {
        window.$("div[data-toggle]").tooltip({});
      }, 1000);
      return {
        data: res.data.transferEvents,
        page: query.page,
        totalCount:
          res.data.tokens[0].transferEventCount === undefined
            ? 0
            : parseInt(res.data.tokens[0].transferEventCount),
      };
    });
};

const TransactionsList = (props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }, []);

  return (
    <div style={{ paddingTop: 50 }}>
      <div className="textCenter textM">
        <span className="title-dash w-700">Transactions</span>
      </div>
      <div className="row" style={{ paddingTop: 50 }}>
        <div className="col-md-4 col-sm-4 col-xs-4 textCenter mlr">
          <div className="ANTRectangleV2 antmob p1M">Sender</div>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-4 textCenter mlr">
          <div className="ANTRectangleV2 antmob p1M">Destination</div>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-4 textCenter mlr">
          <div className="ANTRectangleV2 antmob p1M">Amount</div>
        </div>
      </div>
      <div className="row ANTRectangleTS tabtrans" style={{ paddingBottom: 0 }}>
        {visible ? (
          <MaterialTable
            icons={tableIcons}
            columns={tableColumnConfig}
            data={remoteData}
            options={{
              toolbar: false,
              showFirstLastPageButtons: false,
            }}
            components={{
              OverlayLoading: (props) => (
                <div className="spinner">
                  {" "}
                  <Spinner visible={true} />
                </div>
              ),
            }}
            localization={table_internationalisation}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TransactionsList;
