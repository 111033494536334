import ApolloClient, { gql } from "apollo-boost";

export const tokenClient = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/amir-louatii/ico-token-goerli-v1",
});

export const icoClient = new ApolloClient({
  uri: "https://api.studio.thegraph.com/query/8345/crowdsaleant/v0.0.1",
});

/*export const icoClient = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/icoantsub/bep20-ant",
});*/

export const getTransactionsList = gql`
  query transactionsList(
    $offset: Int!
    $limit: Int!
    $orderBy: String!
    $orderDirection: String!
  ) {
    transferEvents(
      first: $limit
      skip: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      transaction
      sender
      destination
      amount
      timestamp
    }
    tokens {
      transferEventCount
    }
  }
`;

export const getBalancesList = gql`
  query balancesList(
    $offset: Int!
    $limit: Int!
    $orderBy: String!
    $orderDirection: String!
  ) {
    accountBalances(
      first: $limit
      skip: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: {
        account_not_contains: "0x0000000000000000000000000000000000000000"
      }
    ) {
      id
      address
      amount
    }
    tokens {
      holderCount
    }
  }
`;

export const getIcoInfoQuery = gql`
  query icoInfo {
    icos {
      id
      openingTime
      closingTime
      rate
    }
  }
`;
export const getRaisedAmountHistoryQuery = gql`
  query raisedAmountHistory {
    raisedAmountHistories(
      first: 1000
      orderBy: timestamp
      orderDirection: asc
    ) {
      id
      raisedAmount
      timestamp
    }
  }
`;

export function getRaisedAmountHistory() {
  return icoClient
    .query({
      query: getRaisedAmountHistoryQuery,
    })
    .then((res) => {
      return {
        data: res.data.raisedAmountHistories,
      };
    });
}
export function getIcoInfo() {
  return icoClient
    .query({
      query: getIcoInfoQuery,
    })
    .then((res) => {
      return {
        data: res.data.icos,
      };
    });
}

export const getStakingTokenList = gql`
  query stakingTokenList(
    $offset: Int!
    $limit: Int!
    $orderBy: String!
    $orderDirection: String!
    $SubGraphCrowdsaleContractAddress: String!
    $SubGraphFastBonusWallet: String!
  ) {
    transferEvents(
      first: $limit
      skip: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: {
        sender_contains: $SubGraphCrowdsaleContractAddress
        destination_not_contains: $SubGraphFastBonusWallet
      }
    ) {
      id
      transaction
      destination
      amount
      timestamp
    }
    tokens {
      transferEventCount
    }
  }
`;
