import { Link } from "@material-ui/core";
import { Modal, Radio, Spin } from "antd";
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { AppParams } from "../config";
import { connect } from "react-redux";
import {
  SET_REFRESH,
  UPDATE_PURCHASE_IS_FINISHED,
} from "../reducer/Main.reducer";
import { base64AntWalletLogo } from "./AntWalletLogo";
import Disclaimer from "../components/Disclaimer";

class AchatToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalVisible: false,
      amountToReceive: 0,
      amountToSend: 0,
      achatTerminer: false,
      balanceInsuffisante: false,
      calculeEncours: false,
      paymentToken: "USDT",
      isWhitelisted: false,
      showDisclaimer:false,
    };
    this.getAmountToReceive = this.getAmountToReceive.bind(this);
    this.handleGetTokens = this.handleGetTokens.bind(this);
  }

  async getAmountToReceive(event) {
    this.setState({ calculeEncours: true });
    this.setState({ amountToSend: event.target.value });
    if (!event.target.value) {
      this.setState({ amountToReceive: 0 });
      this.setState({ platformFeesAmount: 0 });
    } else {
      var paymentTokenBalance;

      paymentTokenBalance = await AppParams.open2web3.balanceOfPaymentToken(
        this.props.store.walletAddress
      );

      if (parseFloat(event.target.value) > parseFloat(paymentTokenBalance)) {
        this.setState({ balanceInsuffisante: true });
      } else {
        this.setState({ balanceInsuffisante: false });
      }
      try {
        const value = await AppParams.open2web3.getAmountToReceiveFromContract(
          event.target.value
        );

        this.setState({
          amountToReceive: value.valueToReceive,
        });
      } catch (error) {
        console.error(error);
      }

      this.setState({ calculeEncours: false });
    }
  }

  handleGetTokens = async () => {
    if (!this.props.store.walletConnected) {
      this.setState({ modalVisible: true });
      return;
    }
    if (this.state.balanceInsuffisante) return;
    this.props.dispatch({
      type: UPDATE_PURCHASE_IS_FINISHED,
      payload: false,
    });

    try {
      this.setState({ loading: true });
      const allowed = await AppParams.open2web3.allowedUSDT(
        this.props.store.walletAddress,
        AppParams.CrowsdaleContractAddress
      );

      if (
        parseFloat(
          AppParams.open2web3.web3.utils.fromWei(allowed.toString(), "ether")
        ) < parseFloat(this.state.amountToSend)
      ) {
        await AppParams.open2web3.approveUSDT(
          this.props.store.walletAddress,
          AppParams.CrowsdaleContractAddress,
          this.state.amountToSend
        );
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
      return;
    }
    try {
      await AppParams.open2web3.buyTokens(
        this.props.store.walletAddress,
        this.state.amountToSend
      );
      this.setState({ loading: false, achatTerminer: true });
      this.props.dispatch({ type: UPDATE_PURCHASE_IS_FINISHED, payload: true });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
      return;
    }
  };

  handleAddTokenToWallet = async () => {
    // const tokenImage = base64AntWalletLogo;

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await AppParams.open2web3.provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: AppParams.CrowsdaleContractAddress, // The address that the token is at.
            symbol: AppParams.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: AppParams.tokenDecimals, // The number of decimals in the token
            // image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("token added!");
      } else {
        console.log("rejected!");
      }
      this.setState({ achatTerminer: false });
    } catch (error) {
      console.log(error);
      this.setState({ achatTerminer: false });
    }
  };

  handleOnIncrementValue = () => {
    var amount = this.state.amountToSend;
    amount++;
    //this.setState({ amountToSend: amount+'' })

    var event = {
      target: {
        value: amount + "",
      },
    };
    this.getAmountToReceive(event);
  };

  handleOnDecrementValue = () => {
    var amount = this.state.amountToSend;
    if (amount >= 1) {
      amount--;
      amount = Math.round(amount);
      //this.setState({ amountToSend: amount+'' })
      var event = {
        target: {
          value: amount + "",
        },
      };
      this.getAmountToReceive(event);
    } else {
      this.setState({ amountToReceive: 0 });
    }
  };

  paymentTokenChanged = async (e) => {
    this.setState({ paymentToken: e.target.value });
    var event = {
      target: {
        value: this.state.amountToSend,
      },
    };
    this.getAmountToReceive(event);
  };

  componentDidMount() {
    AppParams.open2web3
      .isWhitelisted(this.props.store.walletAddress)
      .then((res) => this.setState({ isWhitelisted: res }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.store.walletAddress !== prevProps.store.walletAddress) {
      if (this.props.walletAddress) {
        AppParams.open2web3
          .isWhitelisted(this.props.store.walletAddress)
          .then((res) => this.setState({ isWhitelisted: res }));
      }
    }
    if (
      this.props.store.kycValid !== prevProps.store.kycValid &&
      this.props.store.kycValid == true
    ) {
      if (this.props.walletAddress) {
        AppParams.open2web3
          .isWhitelisted(this.props.store.walletAddress)
          .then((res) => this.setState({ isWhitelisted: res }));
      }
    }
  }

  closeDisclaimer = () => {
    this.setState({ showDisclaimer: false });
  };
  render() {
    return (
      <>
        <div className="row ANTRectangleTS textCenter  classAlign">
          <div className="rounded-t mb-0 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                {/*<h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                                    Buy tokens
                                </h6>*/}
                {/*
                                <div className="text-xl font-semibold">Buy tokens</div>
                                <div className="btnBuy">You must be logged in to purchases</div>
                                */}
                <div className="flex-auto">
                  <div className="relative">
                    {this.props.store.walletConnected ? (
                      <div>
                        {/*<div>
                                                            <h3>Account: </h3>
                                                        </div>
                                                        <div className="c-lien">
                                                            <Link to="#" >
                                                                {this.props.store.walletAddress}
                                                            </Link>
                                                        </div>
                                                        <div className="mt-10">
                                                            <h2>Balance: <span className="prim-color">{this.props.store.myBalance} {this.props.store.symbol}</span> </h2>
                                                        </div>*/}
                      </div>
                    ) : null}
                    {this.props.store.walletConnected && (
                      <div
                        className="flex justify-between formcontent"
                        style={{ zoom: 0.8 }}
                      >
                        <div className="pr-4 flex-1 centerBO">
                          <div>
                            <div className="mt-10">
                              <h2>
                                Amount to be transferred in{" "}
                                {this.state.paymentToken}:{" "}
                              </h2>
                            </div>
                          </div>

                          <div className="alignIcon">
                            <button
                              className="AntRoadmapItem-btn"
                              onClick={this.handleOnIncrementValue}
                            >
                              <span className="glyphicon glyphicon-chevron-up"></span>
                            </button>

                            <TextField
                              id="standard-number"
                              label=""
                              className="w-full"
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              variant="outlined"
                              onChange={this.getAmountToReceive}
                              value={this.state.amountToSend}
                            />
                            <button
                              className="AntRoadmapItem-btn"
                              onClick={this.handleOnDecrementValue}
                            >
                              <span className="glyphicon glyphicon-chevron-down"></span>
                            </button>
                          </div>

                          {this.state.amountToReceive ? (
                            <label className="label-recevoir">
                              You will receive{" "}
                              {parseFloat(
                                this.state.amountToReceive
                              ).toLocaleString()}{" "}
                              O2I
                            </label>
                          ) : null}
                        </div>
                      </div>
                    )}

                    {this.props.store.walletConnected ? (
                      this.props.store.networkId != AppParams.AppNetworkId ? (
                        <div className="btnBuy">
                          {` Incompatible network, please choose ${AppParams.AppNetworkName}`}
                        </div>
                      ) : (
                        <button
                          className="relative w-full max-w-full flex-grow flex-1 mt-10 btn1"
                          href="#contained-buttons"
                          onClick={() => {
                            if (this.state.amountToSend) {
                              this.setState({ showDisclaimer: true });
                            }
                          }}
                          disabled={
                            this.state.amountToSend == 0 ||
                            !this.state.isWhitelisted ||
                            !this.state.amountToSend ||
                            this.state.balanceInsuffisante ||
                            this.state.calculeEncours ||
                            this.props.store.networkId != AppParams.AppNetworkId
                          }
                        >
                          {this.state.balanceInsuffisante
                            ? "Insufficient USDT balance"
                            : "Buy token"}
                        </button>
                      )
                    ) : (
                      <>
                        <div className="text-xl font-semibold">Buy tokens</div>
                        <div className="btnBuy">
                          You must be logged in to purchases
                        </div>
                      </>
                    )}
                    {/*
                     */}
                    {/*
                                        <div>
                                            <button className="relative w-full max-w-full flex-grow flex-1 mt-10 btn1" href="#contained-buttons"
                                                onClick={this.handleGetTokens} disabled={this.state.balanceInsuffisante || this.state.calculeEncours || this.props.store.networkId != AppNetworkId}>
                                                {

                                                    (this.props.store.walletConnected
                                                        ? this.props.store.networkId != AppNetworkId ? "Incompatible network, please choose the BSC Test networks" : (this.state.balanceInsuffisante ? "Insufficient BUSD balance" : "Confirm the purchase")
                                                        : "You must be logged in to purchase")
                                                }

                                                <div className="btnBuy">You must be logged in to purchases</div>
                                            </button>
                                        </div>
                                         */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="p-4 flex-auto">
                        <div className="relative">
                            {
                                this.props.store.walletConnected ?
                                    (
                                        <div>
                                            <div>
                                                <h3>Account: </h3>
                                            </div>
                                            <div className="c-lien"> 
                                                <Link to="#" >
                                                    {this.props.store.walletAddress}
                                                </Link>
                                            </div>
                                            <div className="mt-10">
                                                <h2>Balance: <span className="prim-color">{this.props.store.myBalance} {this.props.store.symbol}</span> </h2>
                                            </div>
                                        </div>
                                    ) : null
                            }
                            {
                                this.props.store.walletConnected &&
                                <div className="flex justify-between formcontent" style={{ zoom: 0.8 }}>
                                    <div className="pr-4 flex-1">
                                        <div>
                                            <div className="mt-10">
                                                <h2>Amount to be transferred in BUSD: </h2>
                                            </div>
                                        </div>
                                        <div>
                                            <TextField
                                                id="standard-number"
                                                label=""
                                                className="w-full"
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                                variant="outlined"
                                                onChange={this.getAmountToRecieve}
                                                value={this.state.amountToSend}
                                            />
                                        </div>
                                        {this.state.platformFeesAmount ? <label className="label-platformFeesAmount">*Platform fees {parseFloat(this.state.platformFeesAmount).toLocaleString()} BUSD</label> : null}
                                        {this.state.fastBonusAmount ? <label className="label-platformFeesAmount">*Amount to send to fast bonus wallet {parseFloat(this.state.fastBonusAmount).toLocaleString()} {this.props.store.symbol}</label> : null}
                                        {this.state.amountToReceive ? <label className="label-recevoir">You will recieve {parseFloat(this.state.amountToReceive).toLocaleString()} ANT</label> : null}
                                    </div>
                                </div>
                            }

                            <div>
                                <button className="relative w-full max-w-full flex-grow flex-1 mt-10 btn1" href="#contained-buttons"
                                    onClick={this.handleGetTokens} disabled={this.state.balanceInsuffisante || this.state.calculeEncours || this.props.store.networkId != AppNetworkId}>
                                    {
                                       
                                        (this.props.store.walletConnected
                                            ?  this.props.store.networkId != AppNetworkId ? "Incompatible network, please choose the BSC Test networks" : (this.state.balanceInsuffisante ? "Insufficient BUSD balance" : "Confirm the purchase")
                                            : "You must be logged in to purchase")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>*/}
        </div>
        <Modal
          visible={this.state.modalVisible}
          title="Information"
          footer={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn1"
                onClick={(e) => this.setState({ modalVisible: false })}
              >
                OK
              </button>
            </div>
          }
          onOk={(e) => this.setState({ modalVisible: false })}
          onCancel={(e) => this.setState({ modalVisible: false })}
        >
          {this.props.store.kycValid ? (
            <p>You must be logged in to purchase</p>
          ) : (
            <p>Please complete your KYC to buy</p>
          )}
        </Modal>
        <Modal visible={this.state.loading} title="Information" footer={null}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Transaction in progress please wait and do not exit ...</p>
            <Spin />
          </div>
        </Modal>

        <Modal
          className="colorT"
          visible={this.state.achatTerminer}
          title="Information"
          footer={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="BtnH"
                onClick={(e) => this.setState({ achatTerminer: false })}
              >
                Close
              </button>
            </div>
          }
          onOk={(e) => this.setState({ achatTerminer: false })}
          onCancel={(e) => this.setState({ achatTerminer: false })}
        >
          <p>Congratulations! Your purchase has is completed.</p>
          <p>
            Didn't you have the token in MetaMask ? <br /> You can add it
            directly by clicking on the button:{" "}
          </p>
          <div style={{ justifyContent: "center" }}>
            <button
              style={{ color: "black" }}
              className="relative w-full max-w-full flex-grow flex-1 mt-10 BtnH"
              onClick={this.handleAddTokenToWallet}
            >
              Add the token in metamask
            </button>
          </div>
        </Modal>
        <Disclaimer
          visible={this.state.showDisclaimer}
          title={[<div className="d-flex justify-content-center"><h3>Disclaimer</h3></div> ]}
          onCancel={this.closeDisclaimer}
          onOk={async () => {
            await this.handleGetTokens();
            this.closeDisclaimer();
          }}
          footer={[
            <footer>
              <div className="d-flex justify-content-center">
                <button
                  className="BtnH"
                  //   onClick={(e) => this.setState({ achatTerminer: false })}
                >
                  Close
                </button>
              </div>
            </footer>,
          ]}
        />
      </>
    );
  }
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(AchatToken);
