import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import WalletConnectButton from "../common/WalletConnectButton";
import KYCConnectButton from "../common/KYCConnectButton";
import { Modal, Spin } from "antd";
import {
  BsCurrencyDollar,
  BsDiscord,
  BsHouse,
  BsMedium,
  BsTelegram,
  BsTwitter,
} from "react-icons/bs";
import { AppParams } from "../config";
import SwitchDesign from "../common/SwitchDesign";
import styles from "../styles/designParams.json";
// import AntBoxInfo from "../common/AntBoxInfo";
import data from "../styles/designParams.json";
class Layout extends Component {
  state = {
    isMobile: false,
    year: 2023,
  };

  componentDidMount() {
    var isMobile = false;
    if (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPhone|iPad|iPod/i)
    ) {
      isMobile = true;
    } else {
      isMobile = false;
    }
    this.setState({ isMobile: isMobile });
    const year = new Date().getFullYear();
    this.setState({ year: year });
  }

  render() {
    const connected = !!this.props.store.walletAddress;
    const isNotOwner =
      !this.props.store.owner ||
      !this.props.store.walletAddress ||
      this.props.store.owner !== this.props.store.walletAddress;
    return (
      <>
        <SwitchDesign />
        <Spin
          spinning={
            this.props.store.loading && this.props.location.pathname !== "/"
          }
        >
          <main>
            <div className="top-nav">
              <nav className="navbar navbar-inverse">
                <div className="container-fluid pb-30">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#bs-example-navbar-collapse-1"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: 35, marginRight: 20 }}
                        src={styles.logo}
                        alt="ICO"
                      />
                      <div className="d-md-none hM">
                        <WalletConnectButton />
                        <span
                          style={{
                            color: "#7373AB",
                            marginLeft: 20,
                            marginRight: 20,
                          }}
                          className="hM"
                        >
                          <img src="/images/en.png" /> EN
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                  >
                    <ul className="nav navbar-nav nav1">
                      <li
                        className={
                          this.props.location.pathname === "/" ? "active" : ""
                        }
                      >
                        <Link to="/">
                          {" "}
                          <BsHouse fontSize="18" />
                          <span> Home</span>
                        </Link>
                      </li>
                      <li
                        className={
                          this.props.location.pathname === "/token-sale"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/token-sale">
                          {" "}
                          <BsCurrencyDollar fontSize="18" />{" "}
                          <span>Token Sale</span>
                        </Link>
                      </li>
                      {/*
                      {connected && isNotOwner && (
                        <li
                          className={
                            this.props.location.pathname === "/staking"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/staking">
                            {" "}
                            <img src="/images/stack.png" />
                            Staking
                          </Link>
                        </li>
                      )}
                        */}
                      <li
                        className={
                          this.props.location.pathname === "/whitepaper"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/whitepaper" className="dL-none d-md-none">
                          {" "}
                          <img src="/images/list.png" /> Lite Paper
                        </Link>
                      </li>

                      {/*end mobile*/}
                    </ul>

                    {/*partie Desktop*/}
                    <ul className="nav navbar-nav nav2 navbar-right d-md-block">
                      <li>
                        {this.props.store.walletAddress ? (
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${AppParams.blockExplorerUrl}/address/${this.props.store.walletAddress}`}
                          >
                            <span className="lienW">
                              {" "}
                              {this.props.store.walletAddressShortForm}
                            </span>
                          </Link>
                        ) : null}
                      </li>
                      <li>
                        {" "}
                        <Link to="/token-sale">
                          <span className="BtnH">Buy tokens</span>
                        </Link>
                      </li>

                      <li className="dM-none">
                        <WalletConnectButton />
                      </li>
                      <li>
                        <div className="d-block d-md-none">
                          <div className="socialMedia">
                            <div className="socialleft textCenter">
                              <div>
                                <img
                                  src="/images/logo.png"
                                  style={{
                                    height: 70,
                                    margin: 0,
                                    paddingLeft: 0,
                                  }}
                                  alt="logo"
                                />
                                <span>
                                  <span style={{ color: "#6DFFA7" }}>$</span>
                                  {/* <AntBoxInfo.Price valueOnly /> */}
                                </span>
                                <span
                                  style={{ color: "#7373AB" }}
                                  className="dM-none"
                                >
                                  <img src="/images/en.png" />
                                  EN
                                </span>
                              </div>
                              <div>
                                {this.props.store.walletAddress ? (
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="lienW"
                                    href={`${AppParams.blockExplorerUrl}/address/${this.props.store.walletAddress}`}
                                  >
                                    {this.props.store.walletAddressShortForm}
                                  </a>
                                ) : null}
                              </div>
                              <div>
                                <img src="/images/twitter.png" alt="Logo" />
                                <img src="/images/telegram.png" alt="Logo" />
                                <img src="/images/medium.png" alt="Logo" />
                                <img src="/images/discord.png" alt="Logo" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div style={{ position: "relative", marginBottom: 50 }}>
              <KYCConnectButton />{" "}
            </div>
            <div className="content">
              {this.props.children}
              {/* <div className="d-none d-md-block">
                  <div className="socialleft textCenter">
                    <div>
                      <img
                        src="/images/logo.png"
                        style={{ width: 50, marginBottom: 10 }}
                        alt="logo"
                      />
                      <span>
                        <span style={{ color: "#6DFFA7" }}>$</span>
                        {/* <AntBoxInfo.Price valueOnly /> 
                      </span>
                      <span style={{ color: "#7373AB" }}>
                        <img src="/images/en.png" alt="lang" />
                        EN
                      </span>
                    </div>
                    <div>
                      {this.props.store.walletAddress ? (
                        <a
                          target="_blank"
                          className="lienW"
                          rel="noopener noreferrer"
                          href={`${blockExplorerUrl}/address/${this.props.store.walletAddress}`}
                        >
                          {this.props.store.walletAddressShortForm}
                        </a>
                      ) : null}
                    </div>
                    <div>
                      <a
                        href="https://twitter.com/ANTFINANCE2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/images/twitter.png" alt="twitter" />
                      </a>
                      <a
                        href="https://t.me/joinchat/3fUH_4Y7CSk5Zjlk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/images/telegram.png" alt="telegram" />
                      </a>
                      <a
                        href="http://blog.ant-finance.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/images/medium.png" alt="medium" />
                      </a>
                      <a
                        href="https://discord.com/invite/h6JBPGpuUb"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <img src="/images/discord.png" alt="discord" />
                      </a>
                    </div>
                  </div>
                </div>*/}
            </div>
            <footer className="sFooter">
              <span>
                &copy; {`Copyrights ${this.state.year} All Rights Reserved`}{" "}
              </span>
              <span>
                <a href="#" target="_blank" className="me-2">
                  <BsMedium fontSize="22" />
                </a>
                <a href="#" target="_blank" className="me-2">
                  <BsTwitter fontSize="22" />
                </a>
                <a href="#" target="_blank" className="me-2">
                  <BsTelegram fontSize="22" />
                </a>
                <a href="#" target="_blank" className="me-2">
                  <BsDiscord fontSize="22" />
                </a>
              </span>

              <a href={data.termAndConditionLink} target="_blanc">
                Terms and conditions
              </a>
            </footer>
          </main>
        </Spin>

        <Modal
          className="colorT"
          visible={this.state.isMobile}
          title="Information"
          footer={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="BtnH"
                onClick={(e) => this.setState({ isMobile: false })}
              >
                Ok
              </button>
            </div>
          }
          onOk={(e) => this.setState({ isMobile: false })}
          onCancel={(e) => this.setState({ isMobile: false })}
        >
          <p>
            For investment, we recommend using Firefox or Chrome on your
            computer
          </p>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
