import React, { Component, useState } from "react";
import { Line } from "react-chartjs-2";
import { getRaisedAmountHistory } from "../common/graphql";
import { AppParams } from "../config";
import "chartjs-adapter-moment";
import { red } from "@material-ui/core/colors";
import { TextareaAutosize } from "@material-ui/core";
import moment from "moment-timezone";
import HowItWork from "../components/HowItsWorks";

class Dashboard extends Component {
  state = {
    montantObjectif: 0,
    tauxCorporate: 0,
    tauxDeveloppement: 0,
    tauxInvestissement: 0,
    tauxJuridique: 0,
    tauxMarketing: 0,
    weiraised: 0,
    value: 0,
    value1: 0,
    investissement: 0,
    developpement: 0,
    corporate: 0,
    ready: false,
    airDropSended: 0,
    chartLabels: [],
    chartTotalsInvestissement: [],
    chartTotalsCorporateMarketing: [],
    chartTotalsDevJuridique: [],
    percentRaised: 0,
  };

  timestampToDate(value) {
    //moment(Number.parseInt(row["timestamp"])).format('DD/MM/YYYY HH:mm:ss')

    var a = new Date(value * 1000);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    //var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    //var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;//+ ':' + sec ;
    //return time;
    return date + " " + month + " " + year;
  }

  componentDidMount() {
    const dd = localStorage.getItem("data");
    if (dd) {
      const d = JSON.parse(dd);
      if (d) this.setState(d);
    }
    this.getSumAirdropReceived();

    Promise.all([
      fetch(
        AppParams.apiEndpoint +
          "/services/LabarakIcoDB/queryExecutor/queries/getLiquidValues"
      ).then((result) => result.json()),
      AppParams.open2web3.weiraised(),
    ])
      .then(([{ content }, _weiraised]) => {
        const infos = content[0];
        //const weiraised = open2web3.getVirtualWeb3().utils.fromWei(_weiraised + "", "ether");
        //const weiraised = (Number.parseFloat(open2web3.getVirtualWeb3().utils.fromWei(_weiraised + "", "ether"))+1000000.);
        const weiraised = Number.parseFloat(
          AppParams.open2web3
            .getVirtualWeb3()
            .utils.fromWei(_weiraised + "", "ether")
        );

        const value =
          infos.montantObjectif > 0
            ? (weiraised / infos.montantObjectif) * 100
            : 0;
        const valuePercentRaised =
          infos.montantObjectif > 0
            ? (weiraised / (infos.montantObjectif + 1840000)) * 100
            : 0;
        setTimeout(() => {
          this.setState({ ready: true, value1: value });
        }, 100);

        var valueInvestissment = (weiraised * infos.tauxInvestissement) / 100;
        var valueOthers = weiraised - valueInvestissment;
        const data = {
          ...infos,
          weiraised,
          value,
          value1: value,
          percentRaised: valuePercentRaised,
          ready: true,
          investissement: valueInvestissment,
          developpement: (valueOthers * infos.tauxDeveloppement) / 100,
          corporate: (valueOthers * infos.tauxCorporate) / 100,
          marketing: (valueOthers * infos.tauxMarketing) / 100,
          juridique: (valueOthers * infos.tauxJuridique) / 100,
        };
        //console.log("data", data)
        localStorage.setItem("data", JSON.stringify(data));

        this.setState(data);

        getRaisedAmountHistory().then((data) => {
          //console.log("dataaaa", data)
          let labels = [];
          let totalsInvestissement = [];
          let totalsCorporateMarketing = [];
          let totalsDevJuridique = [];
          let tauxCororateMarketing = infos.tauxCorporate + infos.tauxMarketing;
          let tauxDevJuridique = infos.tauxDeveloppement + infos.tauxJuridique;

          const collection = data.data.map((x) => ({
            ...x,
            day: new Date(x.timestamp * 1000),
            value: Number(x.raisedAmount),
          }));

          //console.log(collection);

          const mapDayToMonth = collection.map((x) => ({
            ...x,
            day: x.day.getMonth(),
          }));

          //console.log(mapDayToMonth);

          const sumPerMonth = mapDayToMonth.reduce((acc, cur) => {
            acc[cur.day] = cur.value; // increment or initialize to cur.value
            return acc;
          }, {});
          //console.log(sumPerMonth)
          var i = 0;
          for (var val in sumPerMonth) {
            //console.log("aa",sumPerMonth[val])
            //console.log("bb",sumPerMonth[val])
            labels.push(i);
            //labels.push(this.timestampToDate(data.data[i].timestamp));
            totalsInvestissement.push(
              (sumPerMonth[val] * infos.tauxInvestissement) / 100
            );
            //totalsInvestissement.push(sumPerMonth[val]);
            totalsCorporateMarketing.push(
              ((sumPerMonth[val] -
                (sumPerMonth[val] * infos.tauxInvestissement) / 100) *
                tauxCororateMarketing) /
                100
            );
            totalsDevJuridique.push(
              ((sumPerMonth[val] -
                (sumPerMonth[val] * infos.tauxInvestissement) / 100) *
                tauxDevJuridique) /
                100
            );
            i++;
          }
          // console.log("labels", data, labels);
          this.setState({
            chartLabels: labels,
            chartTotalsInvestissement: totalsInvestissement,
            chartTotalsCorporateMarketing: totalsCorporateMarketing,
            chartTotalsDevJuridique: totalsDevJuridique,
          });
        });
      })
      .catch(console.error);
  }

  getSumAirdropReceived() {
    fetch(
      AppParams.apiEndpoint +
        "/services/LabarakIcoDB/queryExecutor/queries/selectTotalAirdropDistributed"
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
        } else {
          if (res.valueToSend > 0) {
            this.setState({ airDropSended: res.valueToSend });
          }
        }
      });
  }

  render() {
    const {
      tauxDeveloppement,
      tauxJuridique,
      tauxCorporate,
      tauxMarketing,
      tauxInvestissement,
      value,
      value1,
      investissement,
      developpement,
      corporate,
      juridique,
      marketing,
      ready,
    } = this.state;

    return (
      <div>
        <div className="bg-1">
          <div className="ant-container">
            <div className="ant-head">
              <div>
                <ChartLines
                  // labels={this.state.chartLabels.map(() => '')}
                  // labels={this.state.chartLabels.map(() => "")}
                  labels={this.state.chartLabels}
                  dataInvestissement={this.state.chartTotalsInvestissement}
                  dataCorporateMarketing={
                    this.state.chartTotalsCorporateMarketing
                  }
                  dataDevJuridique={this.state.chartTotalsDevJuridique}
                />
              </div>
              <div className="ant-z1">
                <AntInfoBox
                  title="INVESTMENT"
                  percentage={tauxInvestissement.toLocaleString() + " %"}
                  value={investissement}
                />
                <AntInfoBox
                  title="MARKETING & CORPORATE"
                  percentage={
                    (
                      ((100 - tauxInvestissement) *
                        (tauxCorporate + tauxMarketing)) /
                      100
                    ).toLocaleString() + " %"
                  }
                  value={marketing + corporate}
                />
                <AntInfoBox
                  title="LEGAL & DEVELOPMENT"
                  percentage={
                    (
                      ((100 - tauxInvestissement) *
                        (tauxDeveloppement + tauxJuridique)) /
                      100
                    ).toLocaleString() + " %"
                  }
                  value={developpement + juridique}
                />
              </div>
            </div>
            <div className="ant-head1">
              <div className="a">
                <AntTotalBox
                  title="TOTAL FUND"
                  title2="RAISED"
                  value={
                    parseFloat(
                      this.state.percentRaised.toFixed(2)
                    ).toLocaleString() + "%"
                  }
                />
              </div>
              <div className="b">
                <AntTotalBox title="TVL" title2="" value="40%" />
              </div>
              <div className="c">
                <AntTotalValueBox
                  value={parseFloat(
                    (
                      investissement +
                      marketing +
                      corporate +
                      developpement +
                      juridique
                    ).toFixed(2)
                  ).toLocaleString()}
                  $="$"
                />
              </div>
              <div className="d">
                <AntTotalValueBox
                  value={parseFloat(
                    this.state.airDropSended.toFixed(2)
                  ).toLocaleString()}
                  $="$"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-2 roadmap">
          <div className="ant-container">
            <AntTitle title="ROADMAP" />
            <div className="ant-ROADMAP-list">
              <AntRoadmapItem title="January-APRIL" title2="Phase 1" step="1">
                <div className="accordion-body">
                  <ul>
                    <li>
                      Develop the initial concept and whitepaper for the ICO.
                    </li>
                    <li>
                      Build the project team, including developers, marketers,
                      and advisors.
                    </li>
                    <li>
                      Conduct market research to identify potential investors
                      and competitors
                    </li>
                    <li>Develop the ICO website and marketing materials. </li>

                    <li>
                      Launch the pre-ICO marketing campaign to generate interest
                      and attract investors.
                    </li>
                    <li></li>
                    <li>
                      Develop partnerships with other companies or platforms.
                    </li>
                    <li>
                      Launch the pre-ICO sale and start accepting investments.
                    </li>
                  </ul>{" "}
                </div>
              </AntRoadmapItem>
              <AntRoadmapItem
                title="May-August"
                title2="Phase 2"
                step="2"
                inLeft
              >
                <div className="accordion-body">
                  <ul>
                    <li>
                      Build the platform or product that the ICO will fund.
                    </li>
                    <li>
                      Evaluate the success of the pre-ICO sale and adjust the
                      ICO strategy if necessary.
                    </li>
                    <li>
                      Launch the main ICO sale and start accepting investments.
                    </li>
                    <li>
                      Focus on building partnerships and relationships with
                      potential investors and industry influencers.
                    </li>
                    <li>
                      Develop a post-ICO marketing strategy to continue
                      promoting the ICO and product.
                    </li>
                    <li>
                      Finalize the development of the platform or product.
                    </li>
                  </ul>
                </div>
              </AntRoadmapItem>
              <AntRoadmapItem
                title="September-December"
                title2="Phase 3"
                step="3"
              >
                <div className="accordion-body">
                  <ul>
                    <li>Launch the platform or product that the ICO funded.</li>
                    <li>
                      Work on building a community of investors and supporters
                      around the product.
                    </li>
                    <li>
                      Continue to support the platform or product and maintain
                      investor relations.
                    </li>
                    <li>
                      Begin planning for any necessary updates or future
                      developments.
                    </li>
                    <li>
                      Begin the process of listing the ICO token on exchanges.
                    </li>
                  </ul>
                </div>
              </AntRoadmapItem>
              {/* <AntRoadmapItem title="May-July" title2="Phase 4" step="4" inLeft>
                <div className="accordion-body">
                  <ul>
                    <li>Opening of the fourth sales phase</li>
                    <li>Opening of the DAO system</li>
                    <li>Setting up of a European fund</li>
                    <li>Opening of the Startup pools</li>
                    <li>Setting up of a "1 year" event</li>
                  </ul>
                </div>
              </AntRoadmapItem> */}
            </div>
          </div>
        </div>
        <div className="bg-3 roadmap">
          <div className="ant-container AntTitleM">
            <AntTitle title="Start O2B SaaS in easy steps" />
            <div className="ant-steps">
              <AntStep
                step="1"
                title="Download MetaMask."
                description=""
                button="Download"
                link="https://guide.ant-finance.io/v/english-1/using-ant-finance/install-metamask"
                existingBtn={true}
              />
              <AntStep
                step="2"
                title="Connect your wallet"
                description=""
                button="Configure"
                inLeft
              />
              <AntStep
                step="3"
                title="Configure your Metamask on goerli test network"
                description=""
              />
              <AntStep
                step="4"
                title="Buy Ether"
                description="If you're looking to purchase Ether and transfer it to your wallet, there are numerous options available to you."
                inLeft
              />
              <AntStep
                step="5"
                title="Convert Ether to USDT"
                description="In order to participate to the ICO, you'll need to convert your Ether to USDT. It's recommended to keep at least $5 worth of Ether in your wallet to cover any transaction fees that may be incurred"
                button="Add BNB"
              />
              <AntStep
                step="6"
                title="Participate to the ICO"
                description="Navigate to token Sale and complete your purchase"
                inLeft
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function AntStep({
  title,
  description,
  button,
  step,
  inLeft,
  link,
  existingBtn,
}) {
  return (
    <div className={"AntStep " + (inLeft ? "AntStep-inLeft" : "")}>
      <div className="AntStep-box antbox">
        <div className="AntStep-titles">
          <span className="AntStep-title">{title}</span>
          <span className="AntStep-desc">{description}</span>
        </div>
        <div>
          {existingBtn && (
            <div className="AntStep-btn">
              <a href={link} target="_blank" rel="noreferrer">
                {button}{" "}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="AntStep-num">
        <span>{step}</span>
      </div>
      <div className="AntStep-empty"></div>
    </div>
  );
}

function AntRoadmapItem({ title, title2, step, inLeft, children }) {
  const [open, setOpen] = useState(false);

  if (inLeft) {
    return (
      <div className="AntRoadmapItem AntRoadmapItem-inleft">
        <div className="AntRoadmapItem-num">
          <span>{step}</span>
        </div>
        <div className="AntRoadmapItem-box antbox">
          <div className="AntRoadmapItem-titles">
            <span className="AntRoadmapItem-title">{title}</span>
            <span className="AntRoadmapItem-title2">{title2}</span>
            {open ? <div className="zoomIp">{children}</div> : null}
          </div>
          <button className="AntRoadmapItem-btn" onClick={() => setOpen(!open)}>
            {open ? (
              <span className="glyphicon glyphicon-chevron-up"></span>
            ) : (
              <span className="glyphicon glyphicon-chevron-down"></span>
            )}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="AntRoadmapItem">
      <div className="AntRoadmapItem-box antbox">
        <div className="AntRoadmapItem-titles">
          <span className="AntRoadmapItem-title">{title}</span>
          <span className="AntRoadmapItem-title2">{title2}</span>
          {open ? <div className="zoomIp">{children}</div> : null}
        </div>
        <button className="AntRoadmapItem-btn" onClick={() => setOpen(!open)}>
          {open ? (
            <span className="glyphicon glyphicon-chevron-up"></span>
          ) : (
            <span className="glyphicon glyphicon-chevron-down"></span>
          )}
        </button>
      </div>
      <div className="AntRoadmapItem-num">
        <span>{step}</span>
      </div>
    </div>
  );
}

function AntTitle({ title }) {
  return (
    <div className="AntTitle">
      <span>{title}</span>
    </div>
  );
}

function AntTotalValueBox({ value, $ }) {
  return (
    <div className="AntTotalValueBox">
      {value} <span> &nbsp;{$}</span>
    </div>
  );
}

function AntTotalBox({ title, value, title2 }) {
  return (
    <div className="AntTotalBox antbox">
      <span className="AntTotalBox-title">
        {title}
        <br />
        {title2}
      </span>
      <span className="AntTotalBox-value">
        {/*<img src="/images/traceV.png" /> */}
        {value}
      </span>
    </div>
  );
}

function AntInfoBox({ title, percentage, value }) {
  return (
    <div className="AntInfoBox antbox">
      <div>
        <span className="AntInfoBox-title">{title}</span>
        <span className="AntInfoBox-percentage">{percentage}</span>
      </div>
      <span className="AntInfoBox-value">
        {parseFloat(
          (value > 1000 ? value / 1000 : value).toFixed(2)
        ).toLocaleString()}
        {(value > 1000 ? "K" : "") + " USD"}
      </span>
    </div>
  );
}

const ChartLines = React.memo(
  ({
    labels,
    dataInvestissement,
    dataCorporateMarketing,
    dataDevJuridique,
  }) => {
    return (
      <Line
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: "nearest",
            },
          },
          scales: {
            x: {
              //type: 'time',
              //time: {
              //displayFormats: {
              // quarter: 'MMM YYYY'
              //}
              // }
            },
            y: {
              grid: {
                drawBorder: false,
                borderDash: [5, 10],
                color: "#ffffff4d",
              },
            },
          },
        }}
        height={380}
        width={450}
        data={{
          labels: labels,
          tension: 0.5,
          fill: true,
          datasets: [
            {
              label: "INVESTMENT",
              data: dataInvestissement,
              tension: 0.5,
              fill: true,
              borderColor: "red",
              backgroundColor: "#ff4d0f33",
            },
            {
              label: "MARKETING & CORPORATE",
              data: dataCorporateMarketing,
              tension: 0.5,
              fill: true,
              borderColor: "#6DFFA7",
              backgroundColor: "#6dffa74d",
            },
            {
              label: "LEGAL & DEVELOPMENT",
              data: dataDevJuridique,
              tension: 0.5,
              fill: true,
              borderColor: "#00f8ff",
              backgroundColor: "#00f8ff1a",
            },
          ],
        }}
      />
    );
  }
);

export default Dashboard;
