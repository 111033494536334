import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SET_KYC } from "../reducer/Main.reducer";
import { AppParams } from "../config";

function KYCConnectButton({ store, dispatch }) {
  const { walletConnected, walletAddress } = store;
  const [state, setState] = useState(null);
  const [kycInterval, setKycInterval] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!state) {
      dispatch({ type: SET_KYC, payload: true });
    } else if (state === "show") {
      dispatch({ type: SET_KYC, payload: false });
    }
  }, [state]);

  const verifKyc = async () => {
    var interval = setInterval(async function () {
      var value = await verifieIfUserAccountValidated();
      setState(value);
      if (interval != null && value == null) {
        clearInterval(interval);
      }
    }, 3000);

    setKycInterval(interval);
  };

  const verifieIfUserAccountValidated = () => {
    return new Promise((resolve, reject) => {
      if (walletConnected) {
        const api = AppParams.apiEndpoint;
        //setTimeout(() => {
        fetch(
          api +
            "/services/LabarakIcoDB/queryExecutor/queries/selectStatutKycFromByPublicAddress?publicAddress=" +
            walletAddress
        )
          .then((response) => response.json())
          .then((res) => {
            if (res.errors) {
            } else {
              //console.log("kyc status",res.content)
              if (res.content.length > 0) {
                if (res.content[0].statutVerification == "active") {
                  //setState(null);
                  resolve(null);
                } else {
                  //setState('pending');
                  resolve("pending");
                  //verifieIfUserAccountValidated();
                }
              } else {
                //setState('show');
                resolve("show");
                //verifieIfUserAccountValidated();
              }
            }
          });
        //}, 1000);
      } else {
        //setState(null);
        resolve(null);
      }
    });
  };

  const checkingKYC = () => {
    setState("checking");
    AppParams.open2web3
      .isWhitelisted(walletAddress)
      .then((result) => {
        if (!result) {
          if (localStorage.getItem("kyc_" + walletAddress)) {
            setState("pending");
          } else {
            setState("show");
          }
        } else {
          setState(null);
        }
      })
      .catch((error) => {
        console.log("checkingKYC error", error);
      });
  };
  useEffect(async () => {
    if (kycInterval != null) {
      clearInterval(kycInterval);
    }
    setState(null);
    if (walletAddress) {
      setState("checking");
      verifKyc();

      //checkingKYC();
      /*setState("checking")
      var value = await verifieIfUserAccountValidated();
      setState(value)
      while(value == "pending" || value == "show"){
        value = await verifieIfUserAccountValidated();
      }*/
    }
  }, [walletAddress]);

  useEffect(() => {
    const blockpass = null;
    if (walletAddress && state === "show") {
      //fetch(AppParams.apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/blockPassRef").then(result => result.json())
      //.then(refResult => {
      //const blockpass = new window.BlockpassKYCConnect(refResult.blockpassProjectId, { env: 'prod', refId: walletAddress, mainColor: '030239' });
      const blockpass = new window.BlockpassKYCConnect(AppParams.BlockPassId, {
        env: "prod",
        refId: walletAddress,
        mainColor: "030239",
      });
      blockpass.startKYCConnect();
      blockpass.on("KYCConnectLoad", () => {
        console.log("KYCConnectLoad");
        //verifieIfUserAccountValidated();
      });
      blockpass.on("KYCConnectSuccess", () => {
        setState("pending");
      });
      blockpass.on("KYCConnectClose", () => {
        //checkingKYC();
      });
      //})
    }
    return () => {
      if (blockpass) blockpass.stopKYCConnect();
    };
  }, [walletAddress, state]);

  useEffect(() => {
    const t = setInterval(() => {
      setIsActive(!!document.getElementById("blockpass-kyc-web"));
    }, 500);
    return () => {
      clearInterval(t);
    };
  }, []);

  /*useEffect(() => {
    let t;
    if (!isActive && walletAddress) {
      let v = 0; 
      t = setInterval(() => {
        v = v + 1;
        checkingKYC();
        if (v > 3) {
          clearInterval(t);
        }
      }, 3000);
    }
    return () => {
      if (t) clearInterval(t);
    }
  }, [isActive]);*/

  if (!state) return null;
  if (state === "pending")
    return (
      <div style={{ position: "relative" }}>
        <div className="kyc-content">
          Your profile is awaiting validation by an administrator
        </div>
      </div>
    );
  if (state === "checking")
    return (
      <div style={{ position: "relative" }}>
        <div className="kyc-content">KYC Verification ...</div>
      </div>
    );
  return (
    <div style={{ position: "relative" }}>
      <div className="kyc-content">
        <p
          style={{
            cursor: "pointer",
            display: walletConnected ? "inline-block" : "none",
          }}
          id="blockpass-kyc-connect"
        >
          {" "}
          <span className="visible-text">Click here</span> to complete your KYC
        </p>
      </div>
    </div>
  );
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(KYCConnectButton);
